import {connect} from "react-redux";
import {Acquisitions, DispatchProps, InnerProps, StateProps} from "../1.components/Acquisitions";
import {RootState, ThunkDispatch} from "../5.types";
import {
    createClosePoller,
    doAbortSession,
    doCreateArchiveLink,
    doCreateSession,
    doDeleteSession,
    doDownloadReport,
    doGetSessions
} from "../3.actions/thunks/sessions";
import {acquire, cancelSession, changeSessionName, cleanSessions, newSession, setSession} from "../3.actions/sessions";
import {routerTools} from "../6.utils/router";
import {loadFromLocalStorage} from "../6.utils/commons";

function mapStateToProps({sessions, i18n}: RootState): StateProps {
    return {
        sessions: sessions.list,
        total: sessions.total,
        locale: i18n.locale,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetSessions: (query, tableName) => dispatch(doGetSessions(query, tableName)),
        onSetSession: (session) => {
            dispatch(newSession());
            dispatch(changeSessionName(session.name));
            dispatch(acquire());
            dispatch(setSession(session));
            createClosePoller(session, dispatch);
            routerTools.push("/new_session");
        },
        onDownloadReport: (session, locale) => dispatch(doDownloadReport(session, locale)),
        onAbort: async (sessionId?: string | number) => {
            routerTools.replace("/sessions");
            await dispatch(doAbortSession(sessionId));
            dispatch(cancelSession());
        },
        onRetry: async (session) => {
            if (session) {
                dispatch(newSession());
                routerTools.replace("/new_session");
                dispatch(changeSessionName(session.name));
                await dispatch(doCreateSession(loadFromLocalStorage("customization")));
            }
        },
        onCleanSessions: () => {
            dispatch(cleanSessions());
        },
        onDeleteSession: (session) => dispatch(doDeleteSession(session)),
        onDoCreateArchiveLink: (session) => dispatch(doCreateArchiveLink(session)),
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(Acquisitions);
