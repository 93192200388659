import * as React from 'react';
import {Redirect, Route, RouteProps} from 'react-router';
import {AuthScope} from "../5.types/userstate";
import {Forbidden} from "./errors/Forbidden";
import {withAuthenticationRequired} from "@auth0/auth0-react";

export interface ProtectedRouteProps extends RouteProps {
    isAuthenticated: boolean;
    authenticationPath: string;
    userScopes?: AuthScope[];
    allowedScopes?: AuthScope[];
}

function validScopes(userScopes?: AuthScope[], allowedScopes?: AuthScope[]): boolean {
    if (!allowedScopes) {
        return true;
    } else {
        return !!(userScopes && userScopes.some((u) => allowedScopes.indexOf(u) !== -1));
    }
}

export const ProtectedRoute = ({ component, allowedScopes, userScopes, ...args }: ProtectedRouteProps) => (
    <Route
        component={withAuthenticationRequired(component as React.ComponentType, {claimCheck: () => validScopes(userScopes, allowedScopes)})}
        {...args}
    />
);

export class PrivateRoute extends Route<ProtectedRouteProps> {
    public render() {
        let redirectPath: string = "";
        if (!this.props.isAuthenticated) {
            redirectPath = this.props.authenticationPath;
        }

        if (redirectPath) {
            const renderComponent = () => (<Redirect to={{pathname: redirectPath, state: {from: this.props.location}}}/>);
            return <Route {...this.props} component={renderComponent} render={undefined}/>;
        } else {
            if (validScopes(this.props.userScopes, this.props.allowedScopes)) {
                return <Route {...this.props}/>;
            } else {
                return <Route {...this.props} component={Forbidden} render={undefined}/>;
            }
        }
    }
}