import {LandingNames} from "../1.components/landings";

export const SESSION_DURATION_MINUTES = 5;
export const WF_ENDPOINT = process.env.REACT_APP_WF_ENDPOINT;
export const CHG_ENDPOINT = process.env.REACT_APP_CHG_ENDPOINT;
export const BASIC_AUTH = process.env.REACT_APP_BASIC_AUTH;
export const LANDING: LandingNames = process.env.REACT_APP_LANDING as LandingNames;

export const AUTH0_DOMAIN = "auth.kopjra.com";
export const AUTH0_CLIENT_ID = "Vcv7tYFBkTFWQlaHCcUYG3U1YPZPfyte";
export const AUTH0_AUDIENCE = "https://api.gateway.kopjra.com";

export const APP_LOCAL_STORAGE = window.location.origin;
