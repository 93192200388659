import {connect} from "react-redux";
import {Stats, DispatchProps, StateProps, InnerProps} from "../1.components/Stats";
import {RootState, ThunkDispatch} from "../5.types";
import {doGetSessionsStats} from "../3.actions/thunks/sessions";
import {newSession} from "../3.actions/sessions";
import {routerTools} from "../6.utils/router";

function mapStateToProps({sessions, user}: RootState): StateProps {
    return {
        stats: sessions.stats,
        storeUrl: user.info ? user.info.storeUrl : undefined,
        fullName: `${user.info?.firstname || ""} ${user.info?.lastname || ""}`.trim(),
    };
}

function mapDispatchToProps(dispatch:ThunkDispatch): DispatchProps {
    return {
        onGetSessionStats: () => dispatch(doGetSessionsStats()),
        onNewSession: () => { dispatch(newSession()); routerTools.push("/new_session"); },
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(Stats);