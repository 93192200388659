import * as React from "react";
import {useEffect} from "react";
import {Card, Col, Row} from "react-bootstrap";
import {I18n, Translate} from "react-redux-i18n";
import {GetUsersResponse} from "../../7.api/users";
import {WASpinner} from "../WASpinner";
import {FullUser, UserProvider} from "../../5.types/adminstate";
import {UserInfo} from "../../5.types/userstate";
import {isSuperAdmin, tableGetParams} from "../../6.utils/commons";
import {ActionType, Column, DataType, FixedSelectionFilter, FreeTextFilter, GetParams, GlobalActionType, KContainer, KTableLoader, Table} from "@kopjra/uikit";

export interface StateProps {
    users?: GetUsersResponse;
    userInfo?: UserInfo;
}

export interface DispatchProps {
    onGetUsers: (query: GetParams, tableName?: string) => Promise<void>;
    onOpenSessions: (user: FullUser) => void;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const Users: React.FC<Props> = ({userInfo, onGetUsers, users, onOpenSessions}) => {
    const tableName = "admin/users/list";

    useEffect(() => {
        onGetUsers(tableGetParams(tableName)).catch((e) => console.warn("Get Users error", e));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onGetUsers]);

    const actions: ActionType<FullUser>[] = [
        {
            name: <><i className="fal fa-folder-open action-icon"/> <Translate value="admin.users.table.actionOpenSessions"/></>,
            handler: async (datum) =>  {
                onOpenSessions(datum);
            }
        }
    ];

    const globalActions: GlobalActionType[] = [
        {
            bulk: false,
            lefty: false,
            handler: async () => {onGetUsers(tableGetParams(tableName), tableName).catch(console.log)},
            name: <i className="fal fa-sync action-icon"/>,
        },
    ];
    const filterDefinition = [
        new FreeTextFilter("firstname", I18n.t("admin.users.table.name")),
        new FreeTextFilter("lastname", I18n.t("admin.users.table.lastname")),
        new FreeTextFilter("email", I18n.t("admin.users.table.email")),
    ];
    if (isSuperAdmin(userInfo)) {
        filterDefinition.push(new FixedSelectionFilter("provider", I18n.t("admin.users.table.provider"), Object.values(UserProvider).map((key) => ({
            label: I18n.t(`admin.user.userProvider.${key}` as any),
            value: key,
        }))));
    }
    const columns: JSX.Element[] = [
        <Column key={0} colid="firstname" name="admin.users.table.name" type={DataType.GENERIC} sort="firstname" colspan={2} classes={"text-start"}/>,
        <Column key={1} colid="lastname" name="admin.users.table.lastname" type={DataType.GENERIC} sort="lastname" colspan={2}  classes={"text-start"}/>,
        <Column key={2} colid="email" name="admin.users.table.email" type={DataType.GENERIC} sort="email" colspan={isSuperAdmin(userInfo) ? 2 : 4}  classes={"text-start"}/>,
        <Column key={3} colid="crmid" name="admin.users.table.crmid" type={DataType.GENERIC} sort="crmid" colspan={1}  classes={"text-end"}/>,
        <Column key={5} colid="consumedCredits" name="admin.users.table.consumedCredits" type={DataType.GENERIC} sort="consumedCredits" colspan={1}  classes={"text-end"}/>,
        <Column key={5} colid="externalId" name="admin.users.table.externalId" type={DataType.GENERIC} sort="externalId" classes={"text-end"} visible={false}/>,
    ];
    if (isSuperAdmin(userInfo)) {
        columns.push(<Column key={6} colid="provider" name="admin.users.table.provider" type={DataType.GENERIC} sort="provider" colspan={2}  classes={"text-start"}/>);
    }
    columns.push(<Column key={7} colid="actions" type={DataType.ACTIONS} actions={actions} colspan={2} classes="text-end"/>);
    return (
        <KContainer>
            <Row style={{paddingTop: 55}}>
                <Col xs={12}>
                    <Card className="text-center">
                        <Card.Header><Translate value="admin.users.title"/></Card.Header>
                        <Card.Body>
                            {users ? (
                                <Row style={{paddingTop: 50}}>
                                    <Col>
                                        <Table
                                            checkboxes={false}
                                            globalActions={globalActions}
                                            filterDefinition={filterDefinition}
                                            globalWaiter={<WASpinner size={200}/>}
                                            waiter={<WASpinner size={100} variant="dark"/>}
                                            id={tableName}
                                            data={users.results}
                                            total_count={users.total}
                                            loaderFunc={(q: GetParams) => onGetUsers(q)}
                                            loadInterval={60000}
                                            keyField={"userId"}
                                            hideColumnSelector={false}
                                        >
                                            {columns}
                                        </Table>
                                    </Col>
                                </Row>
                            ) : (
                                <Row style={{marginTop: 40, marginBottom: 30}}>
                                    <KTableLoader/>
                                </Row>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </KContainer>
    );
};
