import {GetUsersResponse} from "../7.api/users";
import {GetSessionsResponse} from "../7.api/sessions";

export enum UserProvider {
    "GFL" = "GFL",
    "INTERNAL" = "INTERNAL",
    "KOPJRA" = "KOPJRA",
    "COP" = "COP",
}

export interface User {
    userId: string;
    email: string;
    firstname: string;
    lastname: string;
    provider: UserProvider;
}

export interface FullUser extends User {
    consumedCredits: number;
    crmid?: string;
    externalId?: string;
}

export interface AdminState {
    users?: GetUsersResponse;
    sessions?: GetSessionsResponse;
}