import * as React from "react";
import {Button} from "react-bootstrap";
import {routerTools} from "../../6.utils/router";
import {I18n, Translate} from "react-redux-i18n";
import Toolbar from "../../2.containers/Toolbar";
import iconColor from "../../8.resources/imgs/wf_logo_default.svg";
import {useAuth0} from "@auth0/auth0-react";
import {KContainer, KStandardFooter} from "@kopjra/uikit";

export interface StateProps {
    locale: string;
}

export interface DispatchProps {
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const KopjraLandingPage: React.FC<Props> = ({locale}) => {
    const {loginWithPopup} = useAuth0();
    return (
        <div>
            <Toolbar onLogin={() => loginWithPopup({ui_locales: locale})}/>
            <KContainer fluid={true} className="landing text-center">
                <img src={iconColor} height={180} alt={"Web Forensics"} style={{marginTop: 70}}/>
                <span className={"appName"}>Web Forensics</span>
                <h4 style={{paddingTop: 20}}><Translate value="home.payoff"/></h4>
                <h4 className="call" style={{paddingTop: 50}}><Translate value="home.call"/></h4>
                <div style={{paddingTop: 50}}>
                    <Button
                        variant={"primary"}
                        onClick={() => loginWithPopup({ui_locales: locale})}
                    >
                        <i className="fal fa-sign-in-alt"/> <Translate value="bar.login"/>
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                        variant={"primary"}
                        onClick={() => routerTools.push("https://store.kopjra.com")}
                    >
                        <i className="fal fa-shopping-cart"/> <Translate value="bar.buy"/>
                    </Button>
                </div>
                <h4 style={{paddingTop: 35}}><a className="link" href={`mailto:info@kopjra.com?subject=${I18n.t("home.contactSubject")}&body=${I18n.t("home.contactBody")}`} target="_blank" rel="noopener noreferrer"><Translate value="home.contactus"/></a></h4>
            </KContainer>
            <KStandardFooter/>
            <div className={"text-center"} style={{backgroundColor: "#363d49"}}>
                <Button variant="link" onClick={() => routerTools.push("/login")} style={{color: "#FFFFFF", fontSize: 12, textTransform: "none"}}><Translate value="bar.loginInternal"/></Button>
            </div>
        </div>
    );
};