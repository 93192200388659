import * as React from "react";
import {useEffect, useState} from "react";
import {Badge, Col, Container, Row} from "react-bootstrap";
import {useParams} from "react-router-dom";
import SplitPane from "react-split-pane-r17";
import {FileCategory, Session, SessionFile, SessionStateMap} from "../5.types/sessionsstate";
import {WASpinner} from "./WASpinner";
import {Localize, Translate} from "react-redux-i18n";
import moment from "moment";
import {createLinkToDownload, formatNumber} from "../6.utils/commons";
import {FilesBrowser} from "./FilesBrowser";
import {getFile} from "../7.api/sessions";
import {Preview} from "./Preview";
import {getApiUrl} from "../7.api";
import {Forbidden} from "./errors/Forbidden";
import {KContainer} from "@kopjra/uikit";
import {Feature, hasFeature} from "../6.utils/features";
import {getToken} from "./App";

export interface StateProps {
    detail?: Session;
    isAdmin?: boolean;
}

export interface DispatchProps {
    onGetSession: (id: string) => Promise<void>;
    onDownloadFile: (session: Session, file: SessionFile) => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const DetailBrowser: React.FC<Props> = ({isAdmin, detail, onGetSession, onDownloadFile}) => {
    const {sessionId} = useParams<{sessionId: string}>();
    const refreshId = !detail || detail.id.toString() !== sessionId ? sessionId : undefined;
    const [selected, setSelected] = useState<{link: string, name: string, category: FileCategory} | undefined>(undefined);
    const [previewing, setPreviewing] = useState(false);

    useEffect(() => {
        if (refreshId) {
            onGetSession(refreshId).catch(console.log);
        }
    }, [onGetSession, refreshId]);
    let elapsed: string | undefined;
    if (detail && detail.start && detail.end) {
        const duration = moment.duration(moment(detail.end).diff(moment(detail.start)));
        elapsed = `${formatNumber(Math.floor(duration.asHours()))}:${formatNumber(duration.minutes())}:${formatNumber(duration.seconds())}`;
    }

    return (
        <>
            <div className="parent">
                {refreshId ? (
                    <KContainer>
                        <Row style={{marginTop: 150, marginBottom: 50}}>
                            <Col className="text-center">
                                <WASpinner size={200} variant="light"/>
                            </Col>
                        </Row>
                    </KContainer>
                ) : (detail && detail.softDeleted && !isAdmin) ? (
                    <Forbidden/>
                ) : (
                    <SplitPane split="horizontal" minSize={200} defaultSize="50%" maxSize={-200}>
                        <SplitPane split="vertical" minSize={300} defaultSize={400} maxSize={-800}>
                            <div className="inner">
                                <Container>
                                    <Row className="title">
                                        <Col><Translate value="details.titleSession"/></Col>
                                    </Row>
                                    {detail && detail.softDeleted ? (
                                        <Row style={{marginTop: -20}}>
                                            <Col md={12} className="label"><Badge pill={true} bg="danger"><Translate value="details.deleted"/></Badge></Col>
                                        </Row>
                                    ) : null}
                                    <Row style={{marginTop: 30}}>
                                        <Col md={4} className="label">ID</Col>
                                        <Col md={8} className="value">{detail && detail.id}</Col>
                                    </Row>
                                    <Row>
                                        <Col md={4} className="label"><Translate value="details.name"/></Col>
                                        <Col md={8} className="value">{detail && detail.name}</Col>
                                    </Row>
                                    <Row>
                                        <Col md={4} className="label"><Translate value="details.created"/></Col>
                                        <Col md={8} className="value">{(detail && detail.created) ? <Localize value={moment(detail.created)} dateFormat="date.long"/> : null}</Col>
                                    </Row>
                                    <Row>
                                        <Col md={4} className="label"><Translate value="details.state"/></Col>
                                        <Col md={8} className="value">{detail ? <Translate value={`acquisitions.stateValues.${SessionStateMap[detail.state]}`}/> : null}</Col>
                                    </Row>
                                    <Row>
                                        <Col md={4} className="label"><Translate value="details.elapsed"/></Col>
                                        <Col md={8} className="value">{elapsed ? elapsed : "--"}</Col>
                                    </Row>
                                </Container>
                            </div>
                            <div className="inner">
                                <Row className="title">
                                    <Col><Translate value="details.titleFiles"/></Col>
                                </Row>
                                <FilesBrowser
                                    files={detail ? detail.files : []}
                                    onDownloadSelected={async (categories) => {
                                        const query: any = {categories: categories.join(",")};
                                        if (hasFeature(Feature.AUTH0)) {
                                            try {
                                                const token = await getToken();
                                                if (token) {
                                                    query.token = token;
                                                }
                                            } catch (_err) {
                                                /* noop */
                                            }
                                        }
                                        createLinkToDownload(`partial_session_${detail && detail.id}.zip`, getApiUrl({urlComponent: `/sessions/${detail && detail.id}/files/archive`, query}))
                                    }}
                                    onDownloadSingleFile={(file) => onDownloadFile(detail as Session, file)}
                                    onPreviewFile={async (file) => {
                                        setPreviewing(true);
                                        try {
                                            const fullFile = await getFile(detail as Session, file);
                                            setSelected({link: fullFile.link as string, name: fullFile.name, category: fullFile.category});
                                        } catch (error) {
                                            console.log(error);
                                        }
                                        setPreviewing(false);
                                    }}
                                />
                            </div>
                        </SplitPane>
                        <div className="inner preview">
                            {(!previewing && !selected) ? (
                                <div className="wrapper">
                                    <span className="no-file"><Translate value="details.previewNoFile"/></span>
                                </div>
                            ) : null}
                            {(previewing) ? (
                                <div className="wrapper">
                                    <WASpinner size={200} variant="light"/>
                                </div>
                            ) : selected ? (
                                <Preview link={selected.link} name={selected.name} category={selected.category}/>
                            ) : null}
                        </div>
                    </SplitPane>
                )}
            </div>
        </>
    );
};
