import {ThunkResult} from "../../5.types";
import {RootAction} from "../index";
import {Dispatch} from "redux";
import {getCurrentUser, getProfileUrl, getStoreUrl, getUsers, isAuthenticated, login, logout, resendEmail, resetPassword, updateUserProfile} from "../../7.api/users";
import {checked, checking, gotUsers, setUserInfo, setUserProfileUrl, setUserStoreUrl} from "../users";
import {routerTools} from "../../6.utils/router";
import {removeUndefinedOrNull, transformToUserParams} from "../../6.utils/commons";
import {parse} from "qs";
import { GetParams, refreshing } from "@kopjra/uikit";
import { User } from "@auth0/auth0-react";
import {UserProfileData} from "../../5.types/userstate";

export const doGetUserInfo = (auth0User: User = {}): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    try {
        dispatch(checking());
        const location = routerTools.location;
        const locationQP = parse(location.search, {ignoreQueryPrefix: true});
        await isAuthenticated();
        if (locationQP?.ticket) {
            routerTools.replace(location.pathname);
        }
        const userInfo = removeUndefinedOrNull(await getCurrentUser());
        dispatch(setUserInfo({...{firstname: auth0User.given_name, lastname: auth0User.family_name, picture: auth0User.picture}, ...(userInfo)}));
        dispatch(checked());
    } catch(err) {
        dispatch(checked());
    }
};

export const doLogin = (username: string, password: string, from: string = "/"): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    await login(username, password);
    dispatch(setUserInfo(await getCurrentUser()));
    try {
        dispatch(setUserStoreUrl(await getStoreUrl()));
    } catch (error) {
        console.log(error);
    }
    try {
        dispatch(setUserProfileUrl(await getProfileUrl()));
    } catch (error) {
        console.log(error);
    }
    routerTools.replace(from !== "/login" ? from : "/"); // TODO: store last url to redirect to that one
};

export const doLogout = (redirect: boolean = true): ThunkResult<Promise<void>> => async (dispatch) => {
    if (!redirect) {
        dispatch(checking());
    }
    await logout();
    dispatch(setUserInfo(undefined));
    if (redirect) {
        routerTools.replace("/login");
    }
};

export const doGetUsers = (query: GetParams, tableName?: string): ThunkResult<Promise<void>> => async (dispatch) => {
    if (tableName) {
        dispatch(refreshing(tableName, true));
    }
    try {
        dispatch(gotUsers(await getUsers(transformToUserParams(query))));
    } finally {
        if (tableName) {
            dispatch(refreshing(tableName, false));
        }
    }
};

export const doResendEmail = (): ThunkResult<Promise<void>> => async () => {
    await resendEmail();
}

export const doResetPassword = (): ThunkResult<Promise<void>> => async () => {
    await resetPassword();
}

export const doUpdateUserProfile = (up: UserProfileData): ThunkResult<Promise<void>> => async () => {
    await updateUserProfile(up);
}
