import {GFLLandingPage} from "./GFLLandingPage";
import React from "react";
import KopjraLandingPage from "../../2.containers/landings/KopjraLandingPage";

export enum LandingNames {
    GFL_LANDING = "GFL_LANDING",
    KOPJRA_LANDING = "KOPJRA_LANDING",
}

export const landingMap = {
    [LandingNames.GFL_LANDING]: <GFLLandingPage/>,
    [LandingNames.KOPJRA_LANDING]: <KopjraLandingPage/>
};