import * as React from "react";
import {ReactChild, useEffect, useState} from "react";
import {GetSessionsResponse} from "../../7.api/sessions";
import {Button, Col, Container, Row} from "react-bootstrap";
import {I18n, Translate} from "react-redux-i18n";
import {WASpinner} from "../WASpinner";
import {Session, SessionFinalStates, SessionSimpleState, SessionState, SessionStateMap} from "../../5.types/sessionsstate";
import {routerTools, useQuery} from "../../6.utils/router";
import {User} from "../../5.types/adminstate";
import {getUsers} from "../../7.api/users";
import {error, formatNumber, isSuperAdmin, tableGetParams} from "../../6.utils/commons";
import {UserInfo} from "../../5.types/userstate";
import moment from "moment";
import {
    ActionType,
    Column,
    DataType,
    FixedSelectionFilter,
    FreeTextFilter,
    GetParams,
    GlobalActionType,
    KCard,
    KModal,
    KModalBackdrop,
    KModalVariant,
    KSelect,
    KTableLoader,
    Table
} from "@kopjra/uikit";

export interface StateProps {
    sessions?: GetSessionsResponse;
    userInfo?: UserInfo;
    locale: string;
}

export interface DispatchProps {
    onGetSessions: (query: GetParams, userId: number | string, tableName?: string) => Promise<void>;
    onCleanSessions: () => void;
    onDeleteReport: (session: Session) => Promise<void>;
    onRefund: (session: Session, credits?: number) => Promise<void>;
    onTerminate: (session: Session) => Promise<void>;
    onStop: (session: Session) => Promise<void>;
    onFail: (session: Session) => Promise<void>;
    onChangeOwner: (session: Session, user: User) => Promise<void>;
    onDeleteSession: (session: Session) => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

const terminateStates: SessionState[] = [
    SessionState.CLOSED,
    SessionState.FATAL_FAILED,
    SessionState.VM_ABORTED_TIMEOUT,
    SessionState.VM_ABORTED_ERROR,
    SessionState.VM_ABORTED_MANUAL,
];

const stopStates: SessionState[] = [
    SessionState.GURU_INTERVENTION
];

const failStates: SessionState[] = [
   SessionState.STARTED,
   SessionState.VM_PREPARING,
   SessionState.VM_READY,
];

export const Sessions: React.FC<Props> = ({onDeleteSession, locale, userInfo, onDeleteReport, sessions, onGetSessions, onRefund, onChangeOwner, onFail, onStop, onTerminate}) => {
    const queryParams: URLSearchParams = useQuery();

    const userId = queryParams.get("userId") || "";
    const userName = queryParams.get("userName");
    const userLastName = queryParams.get("userLastName");

    const tableName = "admin/sessions/list";

    const userCompleteName = `${userName || ""} ${userLastName || ""}`.trim();
    useEffect(() => {
        onGetSessions(tableGetParams(tableName), userId).catch((e) => console.warn("Get Admin Sessions error", e));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onGetSessions]);

    const [sessionForChange, setSessionForChange] = useState<Session | undefined>(undefined);
    const [changingOwner, setChangingOwner] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
    const [users, setUsers] = useState<User[] | undefined>(undefined);

    const actions: ActionType<Session>[] = [
        {
            name: <><i className="fal fa-eye action-icon"/> <Translate value="acquisitions.table.actions.view"/></>,
            handler: async (datum: Session) => {routerTools.push(`/sessions/${datum.id}`)},
            shouldRender: (datum: Session) => SessionStateMap[datum.state] === SessionSimpleState.COMPLETED && isSuperAdmin(userInfo),
            tooltip: <Translate value="acquisitions.table.actions.viewtip"/>,
        },
        {
            name: <><i className="fal fa-money-bill action-icon"/> <Translate value="admin.sessions.table.actions.refund"/></>,
            handler: (datum) => onRefund(datum),
            confirmation: true,
            tooltip: <Translate value="admin.sessions.table.actions.refundtip"/>,
            shouldRender: (datum: Session) => datum.consumedCredits > 0,
        },
        {
            name: <><i className="fal fa-money-bill-alt action-icon"/> <Translate value="admin.sessions.table.actions.refundOne"/></>,
            handler: (datum) => onRefund(datum, 1),
            confirmation: true,
            tooltip: <Translate value="admin.sessions.table.actions.refundOneTip"/>,
            shouldRender: (datum: Session) => datum.consumedCredits > 1,
        },
        {
            name: <><i className="fal fa-user-edit action-icon"/> <Translate value="admin.sessions.table.actions.changeOwner"/></>,
            handler: async (datum) => {
                setSessionForChange(datum);
                (async () => {
                    let userResp = await getUsers({top: 1, skip: 0});
                    const total = userResp.total;
                    userResp = await getUsers({top: total, skip: 0});
                    setUsers(userResp.results);
                })().catch((err) => {
                    error(err.message);
                    onHide();
                })
            },
            tooltip: <Translate value="admin.sessions.table.actions.changeOwnertip"/>,
        },
        {
            name: <><i className="fal fa-file-pdf action-icon"/> <Translate value="admin.sessions.table.actions.deletePdf"/></>,
            handler: (datum) => onDeleteReport(datum),
            confirmation: true,
            tooltip: <Translate value="admin.sessions.table.actions.deletePdftip"/>,
        },
        {
            name: <><i className="fal fa-power-off action-icon"/> <Translate value="admin.sessions.table.actions.terminate"/></>,
            handler: (datum) => onTerminate(datum),
            confirmation: true,
            tooltip: <Translate value="admin.sessions.table.actions.terminatetip"/>,
            shouldRender: (datum) => terminateStates.indexOf(datum.state) !== -1
        },
        {
            name: <><i className="fal fa-stop action-icon"/> <Translate value="admin.sessions.table.actions.stop"/></>,
            handler: (datum) => onStop(datum),
            confirmation: true,
            tooltip: <Translate value="admin.sessions.table.actions.stoptip"/>,
            shouldRender: (datum) => stopStates.indexOf(datum.state) !== -1
        },
        {
            name: <><i className="fal fa-skull-crossbones action-icon"/> <Translate value="admin.sessions.table.actions.fail"/></>,
            handler: (datum) => onFail(datum),
            confirmation: true,
            tooltip: <Translate value="admin.sessions.table.actions.failtip"/>,
            shouldRender: (datum) => failStates.indexOf(datum.state) !== -1
        },
        {
            name: <><i className="fal fa-trash action-icon"/> <Translate value="admin.sessions.table.actions.del"/></>,
            handler: async (datum: Session) => {
                await onDeleteSession(datum);
                onGetSessions(tableGetParams(tableName), userId, tableName).catch(console.log);
            },
            confirmation: true,
            shouldRender: (datum: Session) => SessionFinalStates.indexOf(datum.state) !== -1,
            tooltip: <Translate value="admin.sessions.table.actions.deltip"/>,
        },
    ];

    const globalActions: GlobalActionType[] = [
        {
            bulk: false,
            lefty: false,
            handler: async () => {onGetSessions(tableGetParams(tableName), userId, tableName).catch(console.log)},
            name: <i className="fal fa-sync action-icon"/>,
        },
    ];

    function renderSessionState(datum: object): ReactChild {
        const session = datum as Session;
        return <Translate value={`admin.sessions.stateValues.${session.state}`}/>
    }

    function renderSoftDeleted(datum: object): ReactChild {
        const session = datum as Session;
        return session.softDeleted ? <Translate value={`new.yes`}/> : <Translate value={`new.no`}/>;
    }

    function renderId(datum: object): ReactChild {
        const session = datum as Session;
        return <span>{session.id} {session.softDeleted ? <i className="text-danger fal fa-times-circle"/> : null}</span>;
    }

    function renderElapsed(datum: object): ReactChild {
        const session = datum as Session;
        if (session.start && session.end) {
            const elapsed = moment.duration(moment(session.end).diff(moment(session.start)));
            return `${formatNumber(Math.floor(elapsed.asHours()))}:${formatNumber(elapsed.minutes())}:${formatNumber(elapsed.seconds())}`;
        } else {
            return "";
        }
    }

    const onHide = () => {
        setChangingOwner(false);
        setSessionForChange(undefined);
        setUsers(undefined);
    };

    return (
        <Container style={{marginBottom: 50}}>
            <KModal
                show={!!sessionForChange} closeButton={true} backdrop={KModalBackdrop.static}
                header={<Translate value="admin.sessions.coModal.title"/>}
                variant={KModalVariant.primary}
                onHide={onHide}
                footer={changingOwner ? (
                    <div style={{display: "block"}} className="text-center"><i className="fal fa-lg fa-circle-notch fa-spin"/></div>
                ) : (
                    <>
                        <Button variant="secondary" onClick={onHide}><Translate value="admin.sessions.coModal.closeButton"/></Button>
                        <Button variant="primary" type="submit"><Translate value="admin.sessions.coModal.changeButton"/></Button>
                    </>
                )}
                formStuff={{
                    onSubmit: async () => {
                        setChangingOwner(true);
                        if (sessionForChange && selectedUser) {
                            await onChangeOwner(sessionForChange, selectedUser);
                        }
                        onHide();
                    }
                }}
            >
                <KSelect
                    id={"ownerSelection"}
                    options={users ? users.filter((u) => u.userId.toString() !== userId).map((u) => ({label: `${u.firstname} ${u.lastname} (${u.email})`, value: u})) : []}
                    onChange={value => setSelectedUser(value ? value as User : undefined)}
                />
            </KModal>
            <Row style={{paddingTop: 55}}>
                <Col xs={12}>
                    <KCard header={<><Translate value="admin.sessions.title"/>{userCompleteName ? ` (${userCompleteName})` : null}</>}>
                        {userId ? (
                            sessions ? (
                                <Row style={{paddingTop: 50}}>
                                    <Col>
                                        <Table
                                            checkboxes={false}
                                            filterDefinition={[
                                                new FreeTextFilter("name", I18n.t("admin.sessions.table.name")),
                                                new FixedSelectionFilter("state", I18n.t("admin.sessions.table.state"), Object.keys(SessionStateMap).map((key) => ({
                                                    label: I18n.t(`admin.sessions.stateValues.${key}` as any),
                                                    value: key,
                                                }))),
                                                new FixedSelectionFilter("softDeleted", I18n.t("admin.sessions.table.softDeleted"),
                                                    [
                                                        {label: I18n.t("new.all"), value: "true,false"},
                                                        {label: I18n.t("new.yes"), value: "true"},
                                                        {label: I18n.t("new.no"), value: "false"},
                                                    ]
                                                )
                                            ]}
                                            globalActions={globalActions}
                                            globalWaiter={<WASpinner size={200}/>}
                                            waiter={<WASpinner size={100} variant="dark"/>}
                                            id={tableName}
                                            data={sessions.results}
                                            total_count={sessions.total}
                                            loaderFunc={(q: GetParams) => onGetSessions(q, userId)}
                                            loadInterval={60000}
                                            keyField={"id"}
                                        >
                                            <Column colid="id" name="admin.sessions.table.id" type={DataType.GENERIC} render={renderId} sort="id" classes={"text-start"} colspan={1}/>
                                            <Column colid="name" name="admin.sessions.table.name" type={DataType.GENERIC} sort="name" classes={"text-start"} colspan={2}/>
                                            <Column colid="consumedCredits" name="admin.sessions.table.consumedCredits" type={DataType.GENERIC} sort="consumedCredits" classes={"text-end"} colspan={1}/>
                                            <Column colid="created" name="admin.sessions.table.created" type={DataType.DATE} sort="created" classes={"text-center"} colspan={2}/>
                                            <Column colid="elapsed" name="admin.sessions.table.elapsed" render={renderElapsed} type={DataType.GENERIC} sort="elapsed" classes={"text-center"} colspan={1}/>
                                            <Column colid="state" name="admin.sessions.table.state" render={renderSessionState} type={DataType.GENERIC} sort="state" classes={"text-end"} colspan={2}/>
                                            <Column colid="start" name="admin.sessions.table.start" type={DataType.DATETIME} sort="start" classes={"text-center"} visible={false}/>
                                            <Column colid="end" name="admin.sessions.table.end" type={DataType.DATETIME} sort="end" classes={"text-center"} visible={false}/>
                                            <Column colid="signingDate" name="admin.sessions.table.signingDate" type={DataType.DATETIME} sort="signingDate" classes={"text-center"}
                                                         visible={false}/>
                                            <Column colid="softDeleted" name="admin.sessions.table.softDeleted" type={DataType.GENERIC} render={renderSoftDeleted} sort="softDeleted" classes={"text-center"}
                                                         visible={false}/>
                                            <Column colid="lastHeartBeat" name="admin.sessions.table.lastHeartBeat" type={DataType.DATETIME} sort="lastHeartBeat" classes={"text-center"}
                                                         visible={false}/>
                                            <Column colid="actions" type={DataType.ACTIONS} actions={actions} collapsed={true} classes="text-end" />
                                        </Table>
                                    </Col>
                                </Row>
                            ) : (
                                <Row style={{marginTop: 40, marginBottom: 30}}>
                                    <KTableLoader/>
                                </Row>
                           )
                        ) : (
                            <h4><Translate value="admin.sessions.missing" style={{color: "#363D49"}}/><span className="btn-link" style={{cursor: "pointer"}} onClick={() => routerTools.replace("/admin/users")}><Translate value="admin.sessions.back"/></span></h4>
                        )}
                    </KCard>
                </Col>
            </Row>
        </Container>
    );
};
