import {connect} from "react-redux";
import {Toolbar, DispatchProps, StateProps, InnerProps} from "../1.components/Toolbar";
import {RootState, ThunkDispatch} from "../5.types";
import {cancelSession, newSession} from "../3.actions/sessions";
import {routerTools, Routes} from "../6.utils/router";
import {doLogout} from "../3.actions/thunks/users";
import {setLocale} from "react-redux-i18n";
import {cleanUsers} from "../3.actions/users";

function mapStateToProps({sessions, user, i18n}: RootState): StateProps {
    return {
        creatingSession: !!sessions.newSession,
        acquiringSession: !!sessions.newSession?.acquiring,
        userInfo: user.info,
        locale: i18n.locale,
        stats: sessions.stats,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onCancelSession: () => { dispatch(cancelSession()); routerTools.replace(Routes.SESSIONS); },
        onNewSession: () => { dispatch(newSession()); routerTools.push(Routes.NEW_SESSION); },
        onLogout: (redirect) => dispatch(doLogout(redirect)),
        onChangeLocale: (locale: string) => {localStorage.setItem("appLang", locale); dispatch(setLocale(locale));},
        onGoMySessions: () => routerTools.push(Routes.MY_SESSIONS),
        onGoAdmin: () => {
            dispatch(cleanUsers());
            routerTools.push(Routes.ADMIN_USERS);
        },
        onGoDashboard: () => routerTools.push(Routes.SESSIONS),
        onGoAccount: () => {routerTools.push(Routes.USER_ACCOUNT)},
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(Toolbar);