import React, {useEffect} from "react";
// @ts-ignore
import mhtml2html from "mhtml2html";
import {WASpinner} from "./WASpinner";

export interface Props {
    link: string;
}

export const MHtmlViewer: React.FC<Props> = ({link}) => {
    useEffect(() => {
        (async () => {
            try {
                const mhtml = await fetch(link);
                let converted = mhtml2html.convert(await mhtml.text());
                let $html = $("html", converted.window.document);

                $("#mhtmlRoot").html(`<iframe id="innerFrame" style="border: none; flex: 1;"></iframe>`);
                // @ts-ignore
                document.getElementById("innerFrame")?.contentWindow.document.write($html[0].outerHTML);
            } catch (e) {
                console.error(e);
            }
        })();
    }, [link]);
    return (
        <div id="mhtmlRoot" className="text-center">
            <div className="wrapper">
                <WASpinner size={200} variant="light"/>
            </div>
        </div>
    );
};
