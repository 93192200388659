import * as React from "react";
import {useEffect} from "react";
import Toolbar from "../2.containers/Toolbar";
import {Redirect, Route, Switch} from "react-router-dom";
import {SessionArea} from "./SessionArea";
import CreateSession from "../2.containers/CreateSession";
import {Routes, useRouter} from "../6.utils/router";
import {NewSession} from "../5.types/sessionsstate";
import {AcquisitionArea} from "./AcquisitionArea";
import {WASpinner} from "./WASpinner";
import Login from "../2.containers/Login";
import {AuthScope, UserInfo} from "../5.types/userstate";
import {PrivateRoute, ProtectedRoute} from "./PrivateRoute";
import DetailBrowser from "../2.containers/DetailBrowser";
import {landingMap, LandingNames} from "./landings";
import {LANDING} from "../6.utils/constants";
import {NotFound} from "./errors/NotFound";
import Users from "../2.containers/admin/Users";
import Sessions from "../2.containers/admin/Sessions";
import {Feature, hasFeature} from "../6.utils/features";
import {GetTokenSilentlyOptions, useAuth0, User} from "@auth0/auth0-react";
import {KApp} from "@kopjra/uikit";
import UserProfile from "../2.containers/UserProfile";

export interface StateProps {
    session?: NewSession;
    authChecked: boolean;
    userInfo?: UserInfo;
    openedModal: boolean;
}

export interface DispatchProps {
    onCancelSession: () => void;
    onGetUserInfo: (auth0User?: User) => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export let getToken: (o?: GetTokenSilentlyOptions) => Promise<any>;

export const App: React.FC<Props> = ({openedModal, session, onCancelSession, onGetUserInfo, authChecked, userInfo}) => {
    const {location} = useRouter();
    const {getAccessTokenSilently, isAuthenticated, isLoading, user} = useAuth0();
    const defaultRouteParams = {
        isAuthenticated: hasFeature(Feature.AUTH0) ? !!userInfo || !!user : !!userInfo,
        userScopes: userInfo ? userInfo.scopes : [],
        authenticationPath: hasFeature(Feature.AUTH0) ? Routes.HOME : Routes.LOGIN,
    };
    console.log(defaultRouteParams);
    useEffect( () => {
        getToken = getAccessTokenSilently;
        if (!isLoading) {
            onGetUserInfo(user).catch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onGetUserInfo, isAuthenticated, isLoading]);
    useEffect(() => {
        if (location.pathname !== Routes.NEW_SESSION && session) {
            onCancelSession();
        }
    }, [location.pathname, session, onCancelSession]);

    return (!authChecked || isLoading) ? (
        <div style={{position: "fixed", top: 0, left: 0, bottom: 0, right: 0}}>
            <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                <WASpinner size={200} variant="light"/>
            </div>
        </div>
    ) : (
        <KApp>
            {location.pathname === Routes.HOME || location.pathname === "" ? null : <Toolbar/>}
            <Switch>
                {LANDING === LandingNames.KOPJRA_LANDING ? (
                    <ProtectedRoute exact={true} path={Routes.HOME} component={() => <Redirect to={Routes.SESSIONS}/>} allowedScopes={undefined} {...defaultRouteParams}/>
                ) : (
                    <Route exact={true} path={Routes.HOME} render={() => defaultRouteParams.isAuthenticated ? <Redirect to={Routes.SESSIONS}/> : landingMap[LANDING]}/>
                )}
                <PrivateRoute exact={true} path={Routes.SESSIONS} component={SessionArea} allowedScopes={undefined} {...defaultRouteParams}/>
                <PrivateRoute path={Routes.MY_SESSIONS} component={AcquisitionArea} allowedScopes={undefined} {...defaultRouteParams}/>
                <PrivateRoute path={Routes.NEW_SESSION} component={CreateSession} allowedScopes={undefined} {...defaultRouteParams}/>
                <PrivateRoute path={Routes.A_SESSION} component={DetailBrowser} allowedScopes={undefined} {...defaultRouteParams}/>

                <PrivateRoute exact={true} path={Routes.USER_ACCOUNT} component={UserProfile} allowedScopes={undefined} {...defaultRouteParams}/>
                <PrivateRoute path={Routes.ADMIN_USERS} component={Users} allowedScopes={[AuthScope.SUPERADMIN, AuthScope.ADMIN]} {...defaultRouteParams}/>
                <PrivateRoute path={Routes.ADMIN_SESSIONS} component={Sessions} allowedScopes={[AuthScope.SUPERADMIN, AuthScope.ADMIN]} {...defaultRouteParams}/>

                {/* @ts-ignore */ }
                <Route path={Routes.LOGIN} render={(props) => userInfo ? <Redirect to={Routes.SESSIONS}/> : <Login {...props}/>}/>
                <Route path={Routes.ALL} component={NotFound}/>
            </Switch>
            {/*{location.pathname === Routes.HOME || hasSessionId(location) || location.pathname === Routes.NEW_SESSION || location.pathname === "" ? null : <KStandardFooter/>}*/}
        </KApp>
    );
};
