import {
    abortSession,
    changeOwner, createArchiveLink,
    createSession,
    deleteReport,
    deleteSession,
    failVMSession,
    getFile,
    getReportUrl,
    getSession,
    getSessions,
    refundSession,
    stopVMSession,
    terminateVMSession
} from "../../7.api/sessions";
import {ThunkResult} from "../../5.types";
import {RootAction} from "../index";
import {Dispatch} from "redux";
import {
    acquire,
    cancelSession,
    gotAdminSessions,
    gotSessions,
    gotSessionStats,
    removeSessionInAdminList,
    setPoller,
    setSession,
    setSessionDetail,
    toAborted,
    updateSessionInAdminList
} from "../sessions";
import {LongPoller} from "../../6.utils/LongPoller";
import {
    Customization,
    PollerName,
    Session,
    SessionFile,
    SessionSimpleState,
    SessionStateMap
} from "../../5.types/sessionsstate";
import {routerTools} from "../../6.utils/router";
import {
    createLinkToDownload,
    error,
    info,
    ok,
    saveToLocalStorage,
    stopPollers,
    transformToSessionParams
} from "../../6.utils/commons";
import {getStats} from "../../7.api/users";
import {I18n} from "react-redux-i18n";
import {User} from "../../5.types/adminstate";
import {GetParams, refreshing} from "@kopjra/uikit";

export const doGetSessions = (query: GetParams, tableName?: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    if (tableName) {
        dispatch(refreshing(tableName, true));
    }
    try {
        dispatch(gotSessions(await getSessions(transformToSessionParams(query))));
    } finally {
        if (tableName) {
            dispatch(refreshing(tableName, false));
        }
    }
};

export const doGetAdminSessions = (query: GetParams, userId: number | string, tableName?: string): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    if (tableName) {
        dispatch(refreshing(tableName, true));
    }
    try {
        dispatch(gotAdminSessions(await getSessions(transformToSessionParams(query, userId))));
    } finally {
        if (tableName) {
            dispatch(refreshing(tableName, false));
        }
    }
};

export const doGetSessionsStats = (): ThunkResult<Promise<void>> =>
    async (dispatch: Dispatch<RootAction>) => {
        dispatch(gotSessionStats(await getStats()))
    };

export function createStartPoller(session: Session, dispatch: Dispatch<RootAction>) {
    const started = new LongPoller(async () => {
        const s = await getSession(session.id);
        if (s.connection && SessionStateMap[s.state] === SessionSimpleState.READY) {
            started.stop();
            dispatch(setSession(s));
        }
    }, 5000);
    dispatch(setPoller(PollerName.STARTED, started));
    started.start();
}

export function createClosePoller(session: Session, dispatch: Dispatch<RootAction>) {
    const closed = new LongPoller(async () => {
        const s = await getSession(session.id);
        if (SessionStateMap[s.state] === SessionSimpleState.COMPLETED || SessionStateMap[s.state] === SessionSimpleState.FINALIZING || SessionStateMap[s.state] === SessionSimpleState.FAILED || SessionStateMap[s.state] === SessionSimpleState.ABORTED) {
            closed.stop();
            dispatch(cancelSession());
            routerTools.replace("/sessions");
        }
    }, 5000);
    dispatch(setPoller(PollerName.CLOSED, closed));
    closed.start();
}

export const doGetSessionDetail = (id: string): ThunkResult<Promise<void>> => async (dispatch) => {
    dispatch(setSessionDetail(await getSession(id)))
};

export const doCreateSession = (customization: Customization): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>, getState) => {
    const {sessions, i18n} = getState();
    if (sessions.newSession) {
        const {name} = sessions.newSession;

        if (name) {
            dispatch(acquire());
            const {session, statusCode} = await createSession(name, i18n.locale, customization);

            if (session) {
                if (session.customization && session.customization.logoFileId) {
                    customization.logoId = session.customization.logoFileId;
                }
                delete customization.subtitle;
                saveToLocalStorage(customization, "customization");
                dispatch(setSession(session));
                createStartPoller(session, dispatch);
                createClosePoller(session, dispatch);
            } else {
                if (statusCode === 402) {
                    error(I18n.t("error.paymentRequired"));
                } else {
                    error(I18n.t("error.sessionCreation"));
                }
                dispatch(cancelSession());
                routerTools.replace("/sessions");
            }
        } else {
            // TODO: manage name undefined
        }
    } else {
        // TODO: manage newSession undefined
    }
};

export const doAbortSession = (sessionId?: string | number): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>, getState) => {
    const {sessions} = getState();
    if (sessions.newSession) {
        stopPollers(sessions.newSession.pollers);
    }
    if (sessionId) {
        await abortSession(sessionId);
        dispatch(toAborted(sessionId));
    }
};

export const doDownloadFile = (session: Session, file: SessionFile | number): ThunkResult<Promise<void>> => async (dispatch: Dispatch<RootAction>) => {
    const fullFile = await getFile(session, file);
    if (fullFile && fullFile.link) {
        createLinkToDownload(fullFile.name, fullFile.link);
    }
};

export const doCreateArchiveLink = (session: Session): ThunkResult<Promise<string>> => async (dispatch: Dispatch<RootAction>) => {
    return await createArchiveLink(session);
};

export const doDownloadReport = (session: Session, locale: string): ThunkResult<Promise<void>> => async () => {
    try {
        const report = await getReportUrl(session, locale);
        if (report && report.url) {
            createLinkToDownload(`Report_Session_${session.id}_${locale}.pdf`, report.url);
        }
    } catch (err) {
        error(I18n.t("error.pdfGeneration"));
    }
};

export const doDeleteReport = (session: Session, locale: string): ThunkResult<Promise<void>> => async () => {
    try {
        await deleteReport(session, locale);
    } catch (err) {
        error(I18n.t("error.admin.pdfDeletion"));
    }
};

export const doRefundSession = (session: Session, credits?: number): ThunkResult<Promise<void>> => async (dispatch) => {
    try {
        if (await refundSession(session, credits)) {
            dispatch(updateSessionInAdminList(await getSession(session.id)));
            info(I18n.t(credits ? "admin.sessions.msg.refundOne" : "admin.sessions.msg.refund"));
        } else {
            throw new Error(I18n.t("error.admin.refund"));
        }
    } catch (err) {
        error(err.message);
    }
};

export const doChangeOwner = (session: Session, newUser: User): ThunkResult<Promise<void>> => async (dispatch) => {
    try {
        if (await changeOwner(session, newUser)) {
            dispatch(removeSessionInAdminList(session.id));
            info(I18n.t("admin.sessions.msg.changeOwner"));
        } else {
            throw new Error(I18n.t("error.admin.changeOwner"));
        }
    } catch (err) {
        error(err.message);
    }
};

export const doTerminateSession = (session: Session): ThunkResult<Promise<void>> => async (dispatch) => {
    try {
        if (await terminateVMSession(session)) {
            dispatch(updateSessionInAdminList(await getSession(session.id)));
            info(I18n.t("admin.sessions.msg.terminate"));
        } else {
            throw new Error(I18n.t("error.admin.terminate"));
        }
    } catch (err) {
        error(err.message);
    }
};

export const doStopSession = (session: Session): ThunkResult<Promise<void>> => async (dispatch) => {
    try {
        if (await stopVMSession(session)) {
            dispatch(updateSessionInAdminList(await getSession(session.id)));
            info(I18n.t("admin.sessions.msg.stop"));
        } else {
            throw new Error(I18n.t("error.admin.stop"));
        }
    } catch (err) {
        error(err.message);
    }
};

export const doFailSession = (session: Session): ThunkResult<Promise<void>> => async (dispatch) => {
    try {
        if (await failVMSession(session)) {
            dispatch(updateSessionInAdminList(await getSession(session.id)));
            info(I18n.t("admin.sessions.msg.fail"));
        } else {
            throw new Error(I18n.t("error.admin.fail"));
        }
    } catch (err) {
        error(err.message);
    }
};

export const doDeleteSession = (session: Session): ThunkResult<Promise<void>> => async (dispatch) => {
    try {
        await deleteSession(session.id);
        ok(I18n.t("admin.sessions.msg.del"));
    } catch (err) {
        error(I18n.t("error.deleteSession"));
    }
};
