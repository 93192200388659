import * as React from "react";
import {Navbar} from "react-bootstrap";
import {Translate} from "react-redux-i18n";
import {hasSessionId, routerTools, Routes, useRouter} from "../6.utils/router";
import {UserInfo} from "../5.types/userstate";
import {translations} from "../9.locale";
import {isAdmin, isSuperAdmin} from "../6.utils/commons";
import {UserProvider} from "../5.types/adminstate";
import {SessionStats} from "../5.types/sessionsstate";
import {useAuth0} from "@auth0/auth0-react";
import {
    KButton,
    KBUTTON_SIZE,
    KBUTTON_VARIANT,
    KNavbar,
    KNavBrandFixed,
    KNavDropdown,
    KNavDropdownItem,
    KNavLangSelector,
    KNavLink,
    noop
} from "@kopjra/uikit";

export interface StateProps {
    creatingSession: boolean;
    acquiringSession: boolean;
    userInfo?: UserInfo;
    locale: string;
    stats?: SessionStats;
}

export interface DispatchProps {
    onGoMySessions: () => void;
    onGoAdmin: () => void;
    onGoDashboard: () => void;
    onNewSession: () => void;
    onCancelSession: () => void;
    onLogout: (redirect?: boolean) => Promise<void>;
    onChangeLocale: (locale: string) => void;
    onGoAccount: () => void;
}

export interface InnerProps {
    onLogin?: () => Promise<void>;
}

export type Props = StateProps & DispatchProps & InnerProps;

export const Toolbar: React.FC<Props> = ({acquiringSession, onGoAccount, onLogin, stats, onGoDashboard, onGoAdmin, onGoMySessions, creatingSession, onNewSession, onCancelSession, userInfo, onLogout, locale, onChangeLocale}) => {
    const {location} = useRouter();
    const {user, logout} = useAuth0();
    const isAdminSession: boolean = location.pathname.startsWith(Routes.ADMIN_SESSIONS);

    return acquiringSession ? null : (
        <KNavbar dark={creatingSession}
                 brand={(hasSessionId(location) || isAdminSession) ? (
                     <Navbar.Brand><i className="fal fa-lg fa-arrow-left" style={{cursor: "pointer", padding: "15px 0"}} onClick={() => routerTools.goBack()}/></Navbar.Brand>
                 ) : (
                     <KNavBrandFixed appName={"Web Forensics"} iconPartial={"web-forensics"} dark={creatingSession}/>
                 )}
                 rightMost={<>
                     <KNavLangSelector locale={locale} translations={translations} onChangeLocale={loc => onChangeLocale(loc)}/>
                     {(userInfo && stats && (stats.infinite || stats.remaining > 0)) ? (
                         !creatingSession ? (
                             <KButton text={<><i className="fal fa-lg fa-plus"/>&nbsp;&nbsp;<Translate value="bar.createbutton"/></>}
                                      variant={KBUTTON_VARIANT.success}
                                      onClick={onNewSession}
                                      size={KBUTTON_SIZE.xs}
                             />
                         ) : (
                             <KButton text={<><i className="fal fa-lg fa-times"/>&nbsp;&nbsp;<Translate value="bar.cancelbutton"/></>}
                                      variant={KBUTTON_VARIANT.secondary}
                                      onClick={onCancelSession}
                                      size={KBUTTON_SIZE.xs}
                             />
                         )
                     ) : null}
                 </>}
                 statusPageComponentIds={["963l1kbx2v7b"]}
        >
            {userInfo ? (
                <>
                    <KNavLink active={location.pathname.startsWith(Routes.SESSIONS) && !hasSessionId} onClick={() => onGoDashboard()} element={<Translate value="bar.sessions"/>}/>
                    <KNavLink active={location.pathname.startsWith(Routes.MY_SESSIONS)} onClick={() => onGoMySessions()} element={<Translate value="bar.mysessions"/>}/>
                    {isAdmin(userInfo) ? (
                        <KNavLink active={location.pathname.startsWith(Routes.ADMIN)} onClick={() => onGoAdmin()} element={
                            <>
                                {isSuperAdmin(userInfo) ? <i className="fal fa-unlock-alt" style={{marginRight: 2}}/> : <i className="fal fa-lock-alt" style={{marginRight: 2}}/>}
                                <Translate value="bar.admin"/>
                            </>
                        }/>
                    ) : null}
                </>
            ) : null}
            <KNavDropdown id={"user.profile-dropdown"} title={<Translate value="bar.account"/>} versionVerifier={true}>
                {!userInfo ? (
                    <>
                        <div style={{padding: 20, width: "100%"}}>
                            <KButton text={<span><i className="fal fag fa-sign-in"/> <Translate value="bar.login"/></span>} variant={KBUTTON_VARIANT.success} size={KBUTTON_SIZE.sm}
                                     onClick={() => onLogin && onLogin()} fill={true}/>
                        </div>
                        <KNavDropdownItem onClick={() => noop()}>
                            <Translate value="bar.support"/>
                        </KNavDropdownItem>
                    </>
                ) : (
                    <>
                        <KNavDropdownItem onClick={() => onGoAccount()}>
                            <Translate value="bar.profile"/>
                        </KNavDropdownItem>
                        <KNavDropdownItem onClick={() => {
                            if (user?.partner === "opendotcom") {
                                window.open("https://www.opendotcom.it/tts/centro-assistenza.aspx?servId=1114", "_blank");
                            } else {
                                window.open("https://kopjra.atlassian.net/servicedesk/customer/portal/5", "_blank");
                            }
                        }}>
                            <Translate value="bar.support"/>
                        </KNavDropdownItem>
                        <KNavDropdownItem onClick={async () => {
                            await onLogout(userInfo.provider !== UserProvider.KOPJRA);
                            if (userInfo.provider === UserProvider.KOPJRA) {
                                logout({returnTo: "https://webforensics.kopjra.com"});
                            }
                        }}>
                            <Translate value="bar.logout"/>
                        </KNavDropdownItem>
                    </>
                )}
            </KNavDropdown>
        </KNavbar>
    );
};
