import * as React from "react";
import {FileCategory} from "../5.types/sessionsstate";
import {LazyLog} from "react-lazylog";
import ReactPlayer from "react-player";
import {Translate} from "react-redux-i18n";
import {MHtmlViewer} from "./MHtmlViewer";
import {WACZViewer} from "./WACZViewer";

export interface StateProps {
}

export interface DispatchProps {
}

export interface InnerProps {
    link: string;
    category: FileCategory;
    name: string;
}

export type Props = StateProps & DispatchProps & InnerProps;

enum InternalCategory {
    VIDEO = "VIDEO",
    TEXTUAL = "TEXTUAL",
    IMAGE = "IMAGE",
    UNSUPPORTED = "UNSUPPORTED",
    MESSAGE_PCAP = "MESSAGE_PCAP",
    IFRAME = "IFRAME",
    "WACZ" = "WACZ",
}

const CategoryMapper: {[key: string]: InternalCategory} = {
    [FileCategory.KEYS]: InternalCategory.TEXTUAL,
    [FileCategory.LOG]: InternalCategory.TEXTUAL,
    [FileCategory.XML]: InternalCategory.TEXTUAL,
    [FileCategory.VIDEO]: InternalCategory.VIDEO,
    [FileCategory.SCREENSHOT]: InternalCategory.IMAGE,
    [FileCategory.PCAP]: InternalCategory.MESSAGE_PCAP,
    [FileCategory.MHTML]: InternalCategory.IFRAME,
    [FileCategory.WACZ]: InternalCategory.WACZ,
};

export const Preview: React.FC<Props> = ({link, name, category}) => {
    const icat: InternalCategory = CategoryMapper[category] || InternalCategory.UNSUPPORTED;
    let component: JSX.Element | null = null;
    let center: boolean = false;
    switch (icat) {
        case InternalCategory.TEXTUAL:
            component = <LazyLog url={link} selectableLines={true}/>;
            break;
        case InternalCategory.VIDEO:
            component = <div className="videoWrapper"><ReactPlayer url={link} controls={true}/></div>;
            center = true;
            break;
        case InternalCategory.IMAGE:
            component = <img style={{maxWidth: "100%", maxHeight: "100%"}} src={link} alt={link}/>;
            center = true;
        break;
        case InternalCategory.MESSAGE_PCAP:
            component = <div className={"center-preview-message"}><h4 style={{color: "#fff"}}><Translate value="details.messagePCAP" dangerousHTML={true}/></h4></div>
            center = true;
        break;
        case InternalCategory.IFRAME:
            if (name.endsWith(".mhtml")) {
                component = <MHtmlViewer link={link} />;
            }
            break;
        case InternalCategory.WACZ:
            component = <WACZViewer link={link} />;
            break;
        default:
            break;
    }
    return (
        <div style={{width: "100%", height: "100%"}} className={(center) ? "text-center" : ""}>
            {component}
        </div>
    );
};
