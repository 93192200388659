import {connect} from "react-redux";
import {CreateSession, DispatchProps, StateProps, InnerProps} from "../1.components/CreateSession";
import {RootState, ThunkDispatch} from "../5.types";
import {cancelSession, changeSessionName, newSession, setSessionError, unsetSessionError} from "../3.actions/sessions";
import {routerTools} from "../6.utils/router";
import {doAbortSession, doCreateSession} from "../3.actions/thunks/sessions";
import {Customization} from "../5.types/sessionsstate";
import {loadFromLocalStorage} from "../6.utils/commons";

function mapStateToProps({sessions, i18n}: RootState): StateProps {
    return {
        newSession: sessions.newSession,
        session: sessions.session,
        locale: i18n.locale,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onNameChange: (name) => dispatch(changeSessionName(name)),
        onSetError: (name, reconnect= false) => dispatch(setSessionError({name, reconnect})),
        onCancelSession: () => { dispatch(cancelSession()); routerTools.replace("/sessions"); },
        onCreateSession: (customization: Customization) => dispatch(doCreateSession(customization)),
        onUnsetError: () => dispatch(unsetSessionError()),
        onAbort: async (sessionId?: string | number) => {
            await dispatch(doAbortSession(sessionId));
            dispatch(cancelSession());
            routerTools.replace("/sessions");
        },
        onRetry: async (session) => {
            await dispatch(doAbortSession(session && session.id));
            if (session) {
                dispatch(newSession());
                dispatch(changeSessionName(session.name));
                await dispatch(doCreateSession(loadFromLocalStorage("customization")));
            }
        }
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(CreateSession);
