import * as React from "react";
import {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import logoBig from "../8.resources/imgs/wf_icon_large.svg";
import {routerTools, useRouter} from "../6.utils/router";
import {Customization, NewSession, Session} from "../5.types/sessionsstate";
import {I18n, Translate} from "react-redux-i18n";
import {GuacamoleDisplay} from "./GuacamoleDisplay";
import {loadFromLocalStorage, stopPollers} from "../6.utils/commons";
import {buildStyles, CircularProgressbarWithChildren} from "react-circular-progressbar";
import {
    checkTreeCopyPaste,
    compatibilityTexts,
    copyPasteRequirements,
    getBrowserVersion,
    minimumRequirements
} from "../6.utils/BrowserCompatibility";
import {
    confirmBox,
    KButton,
    KBUTTON_SIZE,
    KBUTTON_TYPE,
    KBUTTON_VARIANT,
    KContainer,
    KFileUpload,
    KForm,
    KFormValues,
    KInput,
    KModal,
    KModalBackdrop,
    KModalVariant,
    KSpace
} from "@kopjra/uikit";
import {KColorPicker} from "@kopjra/uikit/dist/src/lib/KColorPicker";
import {Feature, hasFeature} from "../6.utils/features";
import tinycolor from "tinycolor2";

export interface StateProps {
    newSession?: NewSession;
    session?: Session;
    locale: string;
}

export interface DispatchProps {
    onNameChange: (name: string) => void;
    onSetError: (name: string, reconnect: boolean) => void;
    onUnsetError: () => void;
    onCancelSession: () => void;
    onCreateSession: (customization: Customization) => Promise<void>;
    onAbort: (sessionId?: string | number) => void | Promise<void>;
    onRetry: (session?: Session) => void | Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

function renderCheckTree(checkTree: any): JSX.Element[] {
    return Object.keys(checkTree).map((env, index) => (
        <div key={index}>
            {Object.keys(checkTree[env]).map((browser, innerIndex) => (
                <Row key={innerIndex}>
                    <Col className="right">
                        <a href={compatibilityTexts[browser].url} target="_blank" rel="noopener noreferrer">
                            <span className="browserName">{compatibilityTexts[browser].name}</span>
                            <span className="browserVersion">{compatibilityTexts[browser].version}</span>
                        </a>
                    </Col>
                </Row>
            ))}
        </div>
    ));
}

function renderBrowserVersion(): React.JSX.Element {
    const browserVersion = getBrowserVersion();
    const pointPos = browserVersion.browser.version ? browserVersion.browser.version.indexOf(".") : -1;
    const version = browserVersion.browser.version ? browserVersion.browser.version.substring(0, (pointPos !== -1) ? pointPos : undefined) : "";
    return (
        <Row>
            <Col className="right">
                <span className="browserName">{browserVersion.browser.name}</span>
                <span className="browserVersion">{version}</span>
            </Col>
        </Row>
    );
}

enum ValidationState {
    NONE,
    GOOD,
    MEH,
    BAD,
}

function getValidationState(): ValidationState {
    const minRequirements = minimumRequirements();
    const compatible = minRequirements && copyPasteRequirements();
    return (compatible) ? ValidationState.GOOD : (minRequirements ? ValidationState.MEH : ValidationState.BAD);
}

export const CreateSession: React.FC<Props> = ({newSession, session, onNameChange, onSetError, onCancelSession, onCreateSession, onUnsetError, onAbort, onRetry}) => {
    const {location} = useRouter();
    const [customization, setCustomization] = useState<Customization>({});
    const [customOpened, setCustomOpened] = useState(false);

    useEffect(() => {
        document.body.classList.toggle("dark", true);
        return () => {
            document.body.classList.toggle("dark", false);
        };
    }, [location.pathname]);

    useEffect(() => {
        if (newSession && !newSession.acquiring) {
            let loadedCustomization = loadFromLocalStorage("customization") || {};
            setCustomization(loadedCustomization);
            if (loadedCustomization.subtitle || loadedCustomization.logo || loadedCustomization.color) {
                setCustomOpened(true);
            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!newSession ) {
            routerTools.replace("/");
        }
    });

    useEffect( () => {
        return () =>  {
            if (newSession) {
                stopPollers(newSession.pollers);
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const [validationState, setValidationState] = useState<ValidationState>(ValidationState.NONE);
    useEffect( () => {
        if (validationState === ValidationState.NONE) {
            setValidationState(getValidationState());
        }
    }, [setValidationState]); // eslint-disable-line react-hooks/exhaustive-deps

    async function handleSubmit(event: KFormValues) {
        const vs = getValidationState();
        if ((vs !== ValidationState.BAD && vs !== ValidationState.MEH) || await confirmBox({
            noText: I18n.t("new.no"),
            yesText: I18n.t("new.yes"),
            dark: true,
            message: `${(vs === ValidationState.BAD) ? I18n.t("new.notCompatible") : I18n.t("new.minBrowser")} ${I18n.t("new.continue")}`
        })) {
            await onCreateSession(customization);
        }
    }

    return newSession ? newSession.acquiring ? (
            <GuacamoleDisplay show={true} connection={session && session.connection} sessionId={session && session.id} onDisconnect={() => {
                onSetError("new.connectionError", true);
            }} onUnMount={() => {
            }} onAbort={onAbort}/>
        ) : (
        <>
            <KContainer>
                {(newSession.error) ? (
                    <div style={{position: "absolute", top: 0, left: 0, right: 0, minHeight: "100vh", display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        overflowY: "auto", backgroundColor: "#363D49"}} className="errorDisplay">
                        <KButton text={<><i className="fal fa-lg fa-times"/>&nbsp;&nbsp;<Translate value="bar.cancelbutton"/></>}
                                 variant={KBUTTON_VARIANT.secondary}
                                 onClick={() => onAbort(session && session.id)}
                                 size={KBUTTON_SIZE.xs}
                                 style={{position: "fixed", top: 16, right: 17}}
                        />
                        <div className="text-center">
                            <KSpace spaces={2}/>
                            <Row>
                                <Col className="text-center">
                                    <div style={{width: 126, height: 126, display: "inline-block"}}>
                                        <CircularProgressbarWithChildren maxValue={120} value={120} strokeWidth={3} styles={buildStyles({
                                            pathColor: "#EF615E",
                                            trailColor: "rgba(54, 61, 73, 0)",
                                        })}>
                                            <img
                                                alt="logo_big"
                                                src={logoBig}
                                                height={77}
                                            />
                                        </CircularProgressbarWithChildren>
                                    </div>
                                </Col>
                            </Row>
                            <KSpace spaces={3}/>
                            <Row>
                                <Col xl={9} lg={10} md={11} sm={12} className="text-center">
                                    <span style={{color: "#8C9AB1", fontSize: "18px", width: "100%"}}><Translate value="new.errorMessage" dangerousHTML={true}/></span>
                                </Col>
                            </Row>
                            <KSpace spaces={3}/>
                            <Row>
                                <Col xl={9} lg={10} md={11} sm={12} className="text-center">
    `                                <KButton text={<><i className="fal fa-lg fa-redo"/>&nbsp;&nbsp;<Translate value="new.retryButton"/></>}
                                              variant={KBUTTON_VARIANT.primary}
                                              onClick={() => onRetry(session)}
                                              size={KBUTTON_SIZE.lg}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                ) : (
                    <>
                        {/*<Prompt message={() => {*/}
                        {/*    return !store.getState().sessions.newSession ? true : I18n.t("new.leave")*/}
                        {/*}}/>*/}
                        <KSpace/>
                        <Row className="createLogo">
                            <Col className="text-center">
                                <img
                                    alt="logo_big"
                                    src={logoBig}
                                    height={110}
                                />
                            </Col>
                        </Row>
                        <Row className="createTitle">
                            <Col className="text-center">
                                <h2><Translate value={"new.title"}/></h2>
                            </Col>
                        </Row>
                        {(!newSession.acquiring) ? (
                            <>
                                <KModal show={validationState === ValidationState.MEH || validationState === ValidationState.BAD}
                                         header={<Translate value="new.attention"/>}
                                         onHide={() => {setValidationState(ValidationState.NONE)}}
                                         buttonText={<Translate value="new.close"/>}
                                         backdrop={KModalBackdrop.static} closeButton={true} dark={true} variant={(validationState === ValidationState.BAD) ? KModalVariant.danger : KModalVariant.warning}>
                                    <Row style={{marginTop: 10}}>
                                        <Col className={(validationState === ValidationState.MEH ? "compatible-min" : "compatible-no")}>
                                            {(validationState === ValidationState.MEH) ? <Translate value="new.minBrowser"/> : <Translate value="new.notCompatible"/>}
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: -10}}>
                                        <Col className="compatible-subtitle">
                                            <Translate value="new.subtitle"/>
                                        </Col>
                                    </Row>
                                    <div className="compatibility">
                                        <Row style={{marginTop: 20}}>
                                            <Col xs={{span: 4}} className="envName"><Translate value="new.yourBrowser"/></Col>
                                            <Col xs={4}>{renderBrowserVersion()}</Col>
                                        </Row>
                                        <Row style={{marginTop: 20}}>
                                            <Col xs={{span: 4}} className="envName"><Translate value="new.recommended"/></Col>
                                            <Col xs={4}>{renderCheckTree(checkTreeCopyPaste)}</Col>
                                        </Row>
                                    </div>
                                </KModal>
                                <KForm onSubmit={handleSubmit}>
                                    <KSpace spaces={3}/>
                                    <Row>
                                        <Col md={4}>
                                            <KInput id="sessionName"
                                                    label={<Translate value={"new.namelabel"}/>}
                                                    value={newSession.name || ""}
                                                    onChange={({currentTarget}: React.FormEvent<any>) => onNameChange(currentTarget.value)}
                                                    errorMessage={new Map()
                                                        .set("required", <Translate value="new.namerequired"/>)
                                                        .set("pattern", <Translate value="new.patternError" dangerousHTML={true}/>)
                                                    }
                                                    dark={true}
                                                    placeholder={I18n.t("new.defaultName")}
                                                    pattern={"^[A-Za-zÀ-ÿ0-9()_+,;. -]*$"}
                                                    required={true}
                                            />
                                        </Col>
                                    </Row>
                                    {!hasFeature(Feature.GFL) ? (
                                        <>
                                            <KSpace spaces={2}/>
                                            <Row>
                                                <Col md={4}>
                                                    <h2 className="customOpener" onClick={() => setCustomOpened(!customOpened)}><Translate value={"new.customization"}/> <i className={`fal ${customOpened ? "fa-caret-up" : "fa-caret-down"}`}/></h2>
                                                </Col>
                                            </Row>
                                            <Row className="customizationContainer" style={{display: customOpened ? "inherit" : "none"}}>
                                                <Col md={{span: 4, offset: 4}}>
                                                    <KButton text={<Translate value={"new.reset"}/>} variant={KBUTTON_VARIANT.link} onClick={() => {
                                                        setCustomization({});
                                                    }}/>
                                                    <Row>
                                                        <Col>
                                                            <KInput id="subtitle"
                                                                    label={<Translate value={"new.subtitlelabel"}/>}
                                                                    value={customization.subtitle}
                                                                    dark={true}
                                                                    hint={<Translate value={"new.subtitlehint"}/>}
                                                                    onChange={({currentTarget}: React.FormEvent<any>) => {
                                                                        setCustomization({
                                                                            ...customization,
                                                                            subtitle: currentTarget.value
                                                                        });
                                                                    }}
                                                                    validate={(value: string) => value.length <= 100}
                                                                    errorMessage={<Translate value={"new.subtitleError"}/>}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <KFileUpload id={"logo"} label={<Translate value={"new.logolabel"}/>}
                                                                         dark={true}
                                                                         defaultPreloadedImage={customization.logo}
                                                                         showUploadedFilename={false}
                                                                         preloadImage={true}
                                                                         hint={<Translate value={"new.logohint"}/>}
                                                                         onChange={(content, mime) => {
                                                                             setCustomization({
                                                                                 ...customization,
                                                                                 logo: {content: content as string, mime: mime as string},
                                                                                 logoId: undefined,
                                                                             });
                                                                         }}
                                                                         accept={["image/png", "image/jpeg"]}
                                                                         maxSizeInMb={1}
                                                                         maxSizeErrorMessage={<Translate value={"new.logoMaxSize"}/>}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <KSpace/>
                                                    <Row>
                                                        <Col>
                                                            <KColorPicker id={"color"}
                                                                          label={<Translate value={"new.colorlabel"}/>}
                                                                          dark={true}
                                                                          hint={<Translate value={"new.colorhint"}/>}
                                                                          value={customization.color || "#419ED8"}
                                                                          validate={(value) => tinycolor(value).getLuminance() < 0.82}
                                                                          errorMessage={<Translate value={"new.colorError"}/>}
                                                                          onChange={(color) => {
                                                                              setCustomization({
                                                                                  ...customization,
                                                                                  color,
                                                                              });
                                                                          }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </>
                                    ) : null}
                                    <KSpace spaces={4}/>
                                    <Row>
                                        <Col xs={{span: 4}}>
                                            <KButton variant={KBUTTON_VARIANT.primary}
                                                     type={KBUTTON_TYPE.submit}
                                                     text={<><i className="fal fa-lg fa-play"/>&nbsp;&nbsp;<Translate value="new.startButton"/></>}
                                                     size={KBUTTON_SIZE.lg}
                                                     fill={true}
                                            />
                                        </Col>
                                    </Row>
                                </KForm>
                                <KSpace spaces={10}/>
                            </>
                        ) : null}
                    </>
                )}
            </KContainer>
        </>
    ) : null;
};
