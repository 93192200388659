import {LongPoller} from "./LongPoller";
import {GetSessionsParams} from "../7.api/sessions";
import {NewSession, PollerName} from "../5.types/sessionsstate";
import {store} from "../index";
import {GetUsersParams} from "../7.api/users";
import {AuthScope, UserInfo} from "../5.types/userstate";
import {extractGetParamsFromState, GetParams, ParamFilter, setAlert, SortingDirection} from "@kopjra/uikit";
import {APP_LOCAL_STORAGE} from "./constants";

export function formatNumber(n: number): string {
    return (n > 9) ? `${n}` : `0${n}`;
}

export function stopPollers(pollers?: { [key: string]: LongPoller }) {
    if (pollers) {
        for (const pollerKey of Object.keys(pollers)) {
            const poller = pollers[pollerKey];
            poller.stop();
        }
    }
}

export function stopPoller(name: PollerName, newSession?: NewSession) {
    if (newSession && newSession.pollers && newSession.pollers[name]) {
        newSession.pollers[name].stop();
    }
}

export function startPoller(name: PollerName, newSession?: NewSession) {
    if (newSession && newSession.pollers && newSession.pollers[name]) {
        newSession.pollers[name].start();
    }
}

export function transformToSessionParams(query: GetParams, userId?: number | string): GetSessionsParams {
    const result: GetSessionsParams = {
        top: query.top,
        skip: query.skip,
        userId,
    };
    if (query.sort && query.direction !== SortingDirection.NONE) {
        result.sort = `${(query.direction === SortingDirection.UP) ? "-" : ""}${query.sort}`;
    }
    if (query.filter && query.filter.length > 0) {
        const namePf = query.filter.find((pf: ParamFilter) => (pf.name === "name"));
        result.name = namePf ? `~${namePf.value}` : undefined;
        const statePf = query.filter.find((pf: ParamFilter) => (pf.name === "state"));
        result.state = statePf ? statePf.value as string : undefined;
        const softDeletedPf = query.filter.find((pf: ParamFilter) => (pf.name === "softDeleted"));
        result.softDeleted = softDeletedPf ? softDeletedPf.value as string : undefined;
    }
    return result;
}

export function transformToUserParams(query: GetParams): GetUsersParams {
    const result: GetUsersParams = {
        top: query.top,
        skip: query.skip,
    };
    if (query.sort && query.direction !== SortingDirection.NONE) {
        result.sort = `${(query.direction === SortingDirection.UP) ? "-" : ""}${query.sort}`;
    }
    if (query.filter && query.filter.length > 0) {
        const namePf = query.filter.find((pf: ParamFilter) => (pf.name === "firstname"));
        result.firstname = namePf ? `~${namePf.value}` : undefined;
        const lastnamePf = query.filter.find((pf: ParamFilter) => (pf.name === "lastname"));
        result.lastname = lastnamePf ? `~${lastnamePf.value}` : undefined;
        const emailPf = query.filter.find((pf: ParamFilter) => (pf.name === "email"));
        result.email = emailPf ? `~${emailPf.value}` : undefined;
        const providerPf = query.filter.find((pf: ParamFilter) => (pf.name === "provider"));
        result.provider = providerPf ? providerPf.value as string : undefined;
    }
    return result;
}

export interface ReversedMap {
    [key: string]: string[];
}

export function reverseMapFromMap(map: { [key: string]: string }): ReversedMap {
    return Object.keys(map).reduce<ReversedMap>((previousValue, key) => {
        previousValue[map[key]] = (previousValue[map[key]] || []).concat([key]);
        return previousValue;
    }, {});
}

export function createURLSearchParams(obj: any): URLSearchParams {
    const usp = new URLSearchParams();
    for (const key of Object.keys(obj)) {
        if (obj[key] !== undefined && obj[key] !== null) {
            usp.append(key, obj[key]);
        }
    }
    return usp;
}

export function saveToLocalStorage(obj: any, where: string) {
    const json = JSON.stringify(obj);
    localStorage.setItem(`${APP_LOCAL_STORAGE}/${where}`, json);
}

export function loadFromLocalStorage(where: string): any {
    const json = localStorage.getItem(`${APP_LOCAL_STORAGE}/${where}`);
    return json ? JSON.parse(json) : {};
}

// export interface AlertBoxConf {
//     title?: string;
//     message: string;
//     size?: "small" | "sm" |  "large" | "lg" | "extra-large" | "xl";
//     buttonText?: string;
//     func?: () => Promise<void> | void;
//     dark?: boolean;
// }

// export async function alertBox(conf: AlertBoxConf) {
//     return new Promise<void>((resolve) => {
//         bootbox.dialog({
//             title: conf.title,
//             message: conf.message,
//             size: conf.size,
//             closeButton: false,
//             buttons: {
//                 button: {
//                     label: conf.buttonText || "OK",
//                     className: "btn-primary",
//                     callback: async () => {
//                         if (conf.func) {
//                             await conf.func();
//                         }
//                         resolve();
//                     },
//                 }
//             }
//         })
//     });
// }

// export interface ConfirmBoxConf {
//     title?: string;
//     message: string;
//     size?: "small" | "sm" |  "large" | "lg" | "extra-large" | "xl";
//     yesText?: string;
//     yesFunc?: () => Promise<void> | void;
//     noText?: string;
//     noFunc?: () => Promise<void> | void;
//     dark?: boolean;
// }
//
// export async function confirmBox(conf: ConfirmBoxConf): Promise<boolean> {
//     return new Promise<boolean>((resolve) => {
//         store.dispatch(openModal());
//         bootbox.dialog({
//             title: conf.title,
//             message: conf.message,
//             size: conf.size,
//             closeButton: false,
//             className: `wf-boot ${conf.dark ? "wf-boot-dark" : "wf-boot-light"}`,
//             buttons: {
//                 yesButton: {
//                     label: conf.yesText || "YES",
//                     className: "btn-primary",
//                     callback: async () => {
//                         store.dispatch(closeModal());
//                         if (conf.yesFunc) {
//                             await conf.yesFunc();
//                         }
//                         resolve(true);
//                     },
//                 },
//                 noButton: {
//                     label: conf.noText || "NO",
//                     className: "btn-secondary",
//                     callback: async () => {
//                         store.dispatch(closeModal());
//                         if (conf.noFunc) {
//                             await conf.noFunc();
//                         }
//                         resolve(false);
//                     },
//                 }
//             }
//         })
//     });
// }

export function createLinkToDownload(name: string, url: string) {
    const link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("download", `${name}`);
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function info(msg: string) {
    store.dispatch(setAlert(msg, "info"));
}

export function error(msg: string) {
    store.dispatch(setAlert(msg, "error"));
}

export function ok(msg: string) {
    store.dispatch(setAlert(msg, "success"));
}

export function isControlOrCommand(keysym: number): boolean {
    switch (keysym) {
        case 65507:
        case 65508:
        case 65511:
        case 65512:
            return true;
        default:
            return false;
    }
}

export function mapCommandToControl(keysym?: number): number | undefined {
    if (keysym === 65511) {
        return 65507;
    } else if (keysym === 65512) {
        return 65508;
    } else {
        return keysym;
    }
}

export function isAdmin(userInfo?: UserInfo): boolean {
    return !!(userInfo && (userInfo.scopes.indexOf(AuthScope.ADMIN) !== -1 || userInfo.scopes.indexOf(AuthScope.SUPERADMIN) !== -1));
}

export function isSuperAdmin(userInfo?: UserInfo): boolean {
    return !!(userInfo && userInfo.scopes.indexOf(AuthScope.SUPERADMIN) !== -1);
}

export function tableGetParams(id: string): GetParams {
    return extractGetParamsFromState(store.getState(), id);
}

export function removeUndefinedOrNull<T>(obj: T) {
    for (const key of Object.keys(obj)) {
        if ((obj as any)[key] === undefined || (obj as any)[key] === null) {
            delete (obj as any)[key];
        }
    }
    return obj;
}
