import {createAction} from "typesafe-actions";
import {GetSessionsResponse} from "../7.api/sessions";
import {Session, SessionStats} from "../5.types/sessionsstate";
import {LongPoller} from "../6.utils/LongPoller";

export const gotSessions = createAction("sessions/GOT", (response: GetSessionsResponse) => response)();
export const gotAdminSessions = createAction("admin_sessions/GOT", (response: GetSessionsResponse) => response)();
export const cleanAdminSessions = createAction("admin_sessions/CLEAN")();
export const cleanSessions = createAction("sessions/CLEAN")();
export const newSession = createAction("sessions/NEW")();
export const cancelSession = createAction("sessions/CANCEL")();
export const changeSessionName = createAction("sessions/CHANGE_NAME", (name: string) => name)<string>();
export const gotSessionStats = createAction("sessions/GOT_STATS", (stats: SessionStats) => stats)();
export const acquire = createAction("session/ACQUIRE")();
export const setSessionError = createAction("session/SET_ERROR", (error: {name: string, reconnect: boolean}) => error)();
export const unsetSessionError = createAction("session/UNSET_ERROR")();
export const setSession = createAction("session/SET", (session: Session) => session)();
export const updateSessionInAdminList = createAction("adminSession/UPDATE_LIST", (session: Session) => session)();
export const removeSessionInAdminList = createAction("adminSession/REMOVE_LIST", (sessionId: number) => sessionId)();
export const setSessionDetail = createAction("session/SET_DETAIL", (session?: Session) => session)();
export const setPoller = createAction("session/SET_POLLER", (name: string, poller: LongPoller) => ({name, poller}))();
export const toAborted = createAction("session/TO_ABORTED", (sessionId: number | string) => sessionId)<number | string>();
export const openModal = createAction("OPEN_MODAL")();
export const closeModal = createAction("CLOSE_MODAL")();
