import * as React from "react";
import {useState} from "react";
import {FileCategory, SessionFile} from "../5.types/sessionsstate";
import {Translate} from "react-redux-i18n";
import {Button, Form} from "react-bootstrap";
import filesize from "filesize.js";
import {tooltipate} from "@kopjra/uikit";

export interface StateProps {
}

export interface DispatchProps {
}

export interface InnerProps {
    files: SessionFile[];
    onDownloadSelected: (categories: FileCategory[]) => Promise<void>;
    onDownloadSingleFile: (file: SessionFile) => Promise<void>;
    onPreviewFile: (file: SessionFile) => Promise<void>;
}

export type Props = StateProps & DispatchProps & InnerProps;

const fatherCat: Partial<Record<FileCategory, FileCategory>> = {
    [FileCategory.MHTML]: FileCategory.SCREENSHOT,
}

function createCategoryMap(files: SessionFile[]): Map<FileCategory, SessionFile[]> {
    const result: Map<FileCategory, SessionFile[]> = new Map();
    for (const file of files) {
        const actualCat = fatherCat[file.category] || file.category;
        if (result.has(actualCat)) {
            result.get(actualCat)!.push(file);
        } else {
            result.set(actualCat, [file]);
        }
    }
    return result;
}

function createCheckMap(): Map<FileCategory, boolean> {
    const result: Map<FileCategory, boolean> = new Map();
    for (const cat of Object.keys(FileCategory)) {
        result.set(cat as FileCategory, false);
    }
    return result;
}

const iconCatMap = {
    [FileCategory.XML]: "fa-file-code",
    [FileCategory.KEYS]: "fa-file-alt",
    [FileCategory.PCAP]: "fa-file-medical-alt",
    [FileCategory.VIDEO]: "fa-file-video",
    [FileCategory.LOG]: "fa-file-alt",
    [FileCategory.DOWNLOAD]: "fa-file",
    [FileCategory.SCREENSHOT]: "fa-file-image",
    [FileCategory.DOWNLOAD_INDEX]: "fa-file",
    [FileCategory.SCREENSHOT_INDEX]: "fa-file",
    [FileCategory.XML_NOT_SIGNED]: "fa-file",
    [FileCategory.MHTML]: "fa-file-code",
    [FileCategory.WACZ]: "fa-file-zipper",
};

const sortedCatArray = [FileCategory.DOWNLOAD, FileCategory.SCREENSHOT, FileCategory.LOG, FileCategory.VIDEO, FileCategory.PCAP, FileCategory.KEYS, FileCategory.WACZ, FileCategory.XML];
const multiMap: {[key: string]: boolean} = {
    [FileCategory.DOWNLOAD]: true,
    [FileCategory.SCREENSHOT]: true,
    [FileCategory.LOG]: true,
};

export const FilesBrowser: React.FC<Props> = ({files, onDownloadSelected, onDownloadSingleFile, onPreviewFile}) => {
    const catMap = createCategoryMap(files);
    const [checks, setChecks] = useState(createCheckMap());
    const [opened, setOpened] = useState(createCheckMap());
    const [selected, setSelected] = useState<SessionFile | undefined>(undefined);

    function fileElement(file: SessionFile, inner: boolean = false): JSX.Element {
        function onSelected() {
            if (!selected || selected !== file) {
                onPreviewFile(file).catch(console.log);
                setSelected(file);
            }
        }
        return (
            <tr key={`${file.category}_${file.id}`} className={`file-row ${selected === file ? "file-selected-row" : ""}`}>
                <td className="checkCol">{!inner ?  <Form.Check type={"checkbox"} label="" id={`${file.category}-check`} checked={checks.get(file.category)} onChange={(e: any) => {
                    checks.set(file.category, !checks.get(file.category));
                    setChecks(new Map(checks));
                }}/>: null}</td>
                <td className="nameCol" onClick={() => onSelected()}>{inner ? <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> : null}<i className={`fal fa-lg ${iconCatMap[file.category]}`}/>&nbsp;&nbsp;&nbsp;&nbsp;{file.name}</td>
                <td className="typeCol" onClick={() => onSelected()}><Translate value={`fileBrowser.${file.category}` as any}/></td>
                <td className="sizeCol" onClick={() => onSelected()}>{filesize(file.sizeByte)}</td>
                <td className="actionsCol" onClick={() => onSelected()}><Button variant="link" style={{padding: 0}} onClick={async (e: any) => {e.preventDefault(); e.stopPropagation(); await onDownloadSingleFile(file);}}><i className="fal fa-download"/></Button></td>
            </tr>
        )
    }

    function categoryElement(cat: FileCategory, files: SessionFile[]): React.JSX.Element[] {
        const elements: React.JSX.Element[] = [];
        elements.push(
            <tr key={`${cat}_0`} onClick={() => {
                opened.set(cat, !opened.get(cat));
                setOpened(new Map(opened));
            }} className="folder">
                <td className="checkCol"><Form.Check type={"checkbox"} label="" id={`${cat}-check`} checked={checks.get(cat)} onChange={() => {
                    checks.set(cat, !checks.get(cat));
                    setChecks(new Map(checks));
                }} onClick={(e: any) => {e.stopPropagation(); }}/></td>
                <td className="nameCol"><i className={`fal fa-lg ${opened.get(cat) ? "fa-folder-open" : "fa-folder"}`}/>&nbsp;&nbsp;&nbsp;&nbsp;<b><Translate value={`fileBrowser.${cat}` as any}/></b></td>
                <td className="typeCol"/>
                <td className="sizeCol"/>
                <td className="actionsCol"/>
            </tr>
        );
        if (opened.get(cat)) {
            for (const file of files) {
                elements.push(fileElement(file, true));
            }
        }
        return elements;
    }

    const rows = Array.from(sortedCatArray).filter((cat) => catMap.has(cat)).map((cat: FileCategory) => {
        const mappedFiles = catMap.get(cat) as SessionFile[];
        if (!multiMap[cat]) {
            return fileElement(mappedFiles[0]);
        } else {
            return categoryElement(cat, mappedFiles);
        }
    });

    const selectedKeys = Array.from(checks.keys()).filter((key) => checks.get(key));

    return (
        <table className="file-browser">
            <thead>
            <tr>
                <th style={{width: "20px"}}>
                    {tooltipate(
                        <span>
                            <Button variant="link" disabled={selectedKeys.length === 0} style={{padding: 0}}
                                    onClick={() => {
                                        onDownloadSelected(selectedKeys).catch();
                                        setChecks(createCheckMap());
                                    }}
                            >
                                <i className="fal fa-download"/>
                            </Button>
                        </span>, <Translate value="fileBrowser.partialDownload"/>)
                    }</th>
                <th style={{width: "45%"}}><Translate value="fileBrowser.name"/></th>
                <th style={{width: "20%"}}><Translate value="fileBrowser.type"/></th>
                <th style={{width: "20%"}}><Translate value="fileBrowser.size"/></th>
                <th style={{width: "15%"}} align="right"/>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </table>
    );
};
