import {connect} from "react-redux";
import {DispatchProps, InnerProps, StateProps, Users} from "../../1.components/admin/Users";
import {RootState, ThunkDispatch} from "../../5.types";
import {doGetUsers} from "../../3.actions/thunks/users";
import {routerTools} from "../../6.utils/router";
import {cleanAdminSessions} from "../../3.actions/sessions";
import {unregister} from "@kopjra/uikit";

function mapStateToProps({admin, user}: RootState): StateProps {
    return {
        users: admin.users,
        userInfo: user.info,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetUsers: (query, tableName) => dispatch(doGetUsers(query, tableName)),
        onOpenSessions: (user) => {
            dispatch(cleanAdminSessions());
            dispatch(unregister("admin/sessions/list"));
            routerTools.push(`/admin/sessions?userId=${user.userId}&userName=${user.firstname}&userLastName=${user.lastname}`);
        }
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(Users);