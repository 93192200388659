import * as React from "react";
import ContentLoader from "react-content-loader";

export interface StateProps {
}

export interface DispatchProps {
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const StatsLoader: React.FC<Props> = () => {
    return (
        <ContentLoader
            speed={2}
            width={1000}
            height={200}
            viewBox="0 0 1000 200"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x="450" y="18" rx="0" ry="0" width="100" height="64" />
            <rect x="470" y="90" rx="0" ry="0" width="60" height="12" />
            <rect x="229" y="18" rx="0" ry="0" width="100" height="64" />
            <rect x="249" y="90" rx="0" ry="0" width="60" height="12" />
            <rect x="677" y="18" rx="0" ry="0" width="100" height="64" />
            <rect x="697" y="90" rx="0" ry="0" width="60" height="12" />
            <rect x="249" y="119" rx="0" ry="0" width="510" height="8" />
            <rect x="400" y="139" rx="0" ry="0" width="200" height="58" />
        </ContentLoader>
    );
};