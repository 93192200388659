import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import * as serviceWorker from "./serviceWorker";
import {applyMiddleware, createStore} from "redux";
import thunk, {ThunkMiddleware} from "redux-thunk";
import logger from "redux-logger";
import {RootAction} from "./3.actions";
import {RootState} from "./5.types";
import {rootReducer} from "./4.reducers";
import {Provider} from "react-redux";
import {HookedBrowserRouter, routerTools} from "./6.utils/router";
import {loadTranslations, setLocale, syncTranslationWithStore} from "react-redux-i18n";
import {defaultLocale, translations} from "./9.locale";
import App from "./2.containers/App";
import {AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN} from "./6.utils/constants";
import {AppState, Auth0Provider} from "@auth0/auth0-react";
import {KAlertProvider} from "@kopjra/uikit";

export const store = createStore(rootReducer, applyMiddleware(thunk as ThunkMiddleware<RootState, RootAction>, logger));

syncTranslationWithStore(store);

store.dispatch(loadTranslations(translations));
store.dispatch(setLocale(defaultLocale));

// Customizing Auth0 Callback handling using the custom router
const onRedirectCallback = (appState?: AppState) => {
    // Use the router's history module to replace the url
    routerTools.replace(appState?.returnTo || window.location.pathname);
};

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Auth0Provider
                onRedirectCallback={onRedirectCallback}
                domain={AUTH0_DOMAIN}
                clientId={AUTH0_CLIENT_ID}
                redirectUri={window.location.origin}
                audience={AUTH0_AUDIENCE}
            >
                <HookedBrowserRouter>
                    <KAlertProvider timeout={3000}>
                        <App/>
                    </KAlertProvider>
                </HookedBrowserRouter>
            </Auth0Provider>
        </Provider>
    </React.StrictMode>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
