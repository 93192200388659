import {connect} from "react-redux";
import {DispatchProps, InnerProps, Sessions, StateProps} from "../../1.components/admin/Sessions";
import {RootState, ThunkDispatch} from "../../5.types";
import {doChangeOwner, doDeleteReport, doDeleteSession, doFailSession, doGetAdminSessions, doRefundSession, doStopSession, doTerminateSession} from "../../3.actions/thunks/sessions";
import {cleanAdminSessions} from "../../3.actions/sessions";

function mapStateToProps({admin, user, i18n}: RootState): StateProps {
    return {
        sessions: admin.sessions,
        userInfo: user.info,
        locale: i18n.locale,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetSessions: async (query, userId, tableName) => dispatch(doGetAdminSessions(query, userId, tableName)),
        onCleanSessions: () => dispatch(cleanAdminSessions()),
        onChangeOwner: (session, user) => dispatch(doChangeOwner(session, user)),
        onRefund: (session, credits) => dispatch(doRefundSession(session, credits)),
        onTerminate: session => dispatch(doTerminateSession(session)),
        onFail: session => dispatch(doFailSession(session)),
        onStop: session => dispatch(doStopSession(session)),
        onDeleteReport: async (session) => {await dispatch(doDeleteReport(session, "it")); await dispatch(doDeleteReport(session, "en"));},
        onDeleteSession: (session) => dispatch(doDeleteSession(session)),
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(Sessions);
