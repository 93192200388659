import {connect} from "react-redux";
import {Dispatch} from "redux";
import {KopjraLandingPage, DispatchProps, StateProps, InnerProps} from "../../1.components/landings/KopjraLandingPage";
import {RootState} from "../../5.types";
import {RootAction} from "../../3.actions";

function mapStateToProps({i18n}: RootState): StateProps {
    return {
        locale: i18n.locale,
    };
}

function mapDispatchToProps(dispatch: Dispatch<RootAction>): DispatchProps {
    return {};
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(KopjraLandingPage);