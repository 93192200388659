import * as React from "react";
import {Col, Row} from "react-bootstrap";
import Stats from "../2.containers/Stats";
import Acquisitions from "../2.containers/Acquisitions";
import {KContainer, KSpace} from "@kopjra/uikit";

export interface StateProps {
}

export interface DispatchProps {
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const SessionArea: React.FC<Props> = () => {
    return (
        <KContainer>
            <KSpace/>
            <Row>
                <Col xs={12}><Stats/></Col>
            </Row>
            <KSpace spaces={3}/>
            <Row>
                <Col xs={12}><Acquisitions reduced={true}/></Col>
            </Row>
        </KContainer>
    );
};