import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {cleanUsers, gotUsers} from "../3.actions/users";
import {AdminState} from "../5.types/adminstate";
import {cleanAdminSessions, gotAdminSessions, removeSessionInAdminList, updateSessionInAdminList} from "../3.actions/sessions";

const initialState: AdminState = {
    users: undefined,
    sessions: undefined,
};

export const adminReducer = createReducer(initialState)
    .handleAction(gotUsers, (state, action) => update(state, {
        users: {$set: action.payload},
    }))
    .handleAction(cleanUsers, (state) => update(state, {
        users: {$set: undefined},
    }))
    .handleAction(gotAdminSessions, (state, action) => update(state, {
        sessions: {$set: action.payload},
    }))
    .handleAction(cleanAdminSessions, (state) => update(state, {
        sessions: {$set: undefined},
    }))
    .handleAction(updateSessionInAdminList, (state, action) => {
        const index = state.sessions ? state.sessions.results.findIndex((s) => s.id === action.payload.id) : -1;
        if (index === -1) {
            return state;
        } else {
            return update(state, {
                sessions: {
                    results: {[index]: {$set: action.payload}},
                },
            });
        }
    })
    .handleAction(removeSessionInAdminList, (state, action) => {
        const index = state.sessions ? state.sessions.results.findIndex((s) => s.id === action.payload) : -1;
        if (index === -1) {
            return state;
        } else {
            return update(state, {
                sessions: {results : {$splice: [[index, 1]]}},
            });
        }
    })
;
