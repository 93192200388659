import {connect} from "react-redux";
import {DispatchProps, InnerProps, StateProps, UserProfile} from "../1.components/UserProfile";
import {RootState, ThunkDispatch} from "../5.types";
import {doResendEmail, doResetPassword, doUpdateUserProfile} from "../3.actions/thunks/users";

function mapStateToProps({user}: RootState): StateProps {
    return {extUser: user.info};
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onResetPassword: () => dispatch(doResetPassword()),
        onUpdateUserProfile: (up) => dispatch(doUpdateUserProfile(up)),
        onResendEmail: () => dispatch(doResendEmail()),
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(UserProfile);