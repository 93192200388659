import * as React from "react";
import {Row, Container, Col} from "react-bootstrap";
import {Translate} from "react-redux-i18n";
import {useState} from "react";

export interface StateProps {
}

export interface DispatchProps {
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const Forbidden: React.FC<Props> = () => {
    const [egg, setEgg] = useState(false);

    const handleEasterEgg = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (event.altKey) {
            setEgg(!egg);
        }
    };

    const zero = (egg) ? <i className="swg swg-jediorder" onClick={handleEasterEgg}/> : <span onClick={handleEasterEgg}>0</span>;
    const message = (egg) ? <span className="message">Are you a JEDI?!!?</span> : <Translate value="error.forbidden" className="message"/>;
    return (
        <Container className="errorPage">
            <Row>
                <Col className="error"><Translate value="error.page"/></Col>
            </Row>
            <Row>
                <Col className="code">4{zero}3</Col>
            </Row>
            <Row>
                <Col>{message}</Col>
            </Row>
            {(egg) ? (
                <Row>
                    <Col>
                        <i style={{fontSize: 80, marginTop: 50}} className="swg swg-darthvader-4"/>
                        <i style={{fontSize: 80, marginTop: 50}} className="swg swg-lightsabers"/>
                        <i style={{fontSize: 80, marginTop: 50}} className="swg swg-yoda-3"/>
                    </Col>
                </Row>
            ) : null}
        </Container>
    );
};