import * as React from "react";
import { Image } from "react-bootstrap";
import lightAnim from "../8.resources/imgs/wf_icon_path_animated_clear.svg";
import darkAnim from "../8.resources/imgs/wf_icon_path_animated_dark.svg";

export interface StateProps {
}

export interface DispatchProps {
}

export interface Props extends StateProps, DispatchProps {
    size: number;
    variant?: "light" | "dark" | undefined;
}

export const WASpinner: React.FC<Props> = ({ size, variant = undefined }) => {
    if (variant === "dark") {
        return <object type="image/svg+xml" data={darkAnim} style={{width: size}}><Image src={darkAnim} height={size}/></object>;
    } else {
        return <object type="image/svg+xml" data={lightAnim} style={{width: size}}><Image src={lightAnim} height={size}/></object>;
    }
};