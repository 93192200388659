import {connect} from "react-redux";
import {Login, DispatchProps, StateProps, InnerProps} from "../1.components/Login";
import {RootState, ThunkDispatch} from "../5.types";
import {doLogin} from "../3.actions/thunks/users";

function mapStateToProps(): StateProps {
    return {};
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onLogin: (username, password, from) => dispatch(doLogin(username, password, from)),
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(Login);