import * as React from "react";

export interface StateProps {
}

export interface DispatchProps {
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const GFLLandingPage: React.FC<Props> = () => {
    window.location.href = "/1/index.html";
    return null;
};