import React, {useEffect} from "react";
import {delay} from "@kopjra/uikit";

export interface Props {
    link: string;
}

export const WACZViewer: React.FC<Props> = ({link}) => {
    useEffect(() => {
        (async () => {
            try {
                for (let i = 0; i < 30; i++) {
                    await delay(1000);
                    // @ts-ignore
                    const iframe = $($("replay-web-page")[0].shadowRoot).find("iframe");
                    const main = iframe.contents().find("replay-app-main")[0];
                    const element = $(main.shadowRoot).find("wr-coll");
                    if (element.length > 0) {
                        element.css("background-color", "#FFFFFF");
                        break;
                    }
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [link]);
    // @ts-ignore
    return (<replay-web-page embed={""} loading={"eager"} source={link} replayBase={"/js/"}></replay-web-page>);
};
