import * as React from "react";
import {Col, Row} from "react-bootstrap";
import Acquisitions from "../2.containers/Acquisitions";
import {KContainer, KSpace} from "@kopjra/uikit";

export interface StateProps {
}

export interface DispatchProps {
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const AcquisitionArea: React.FC<Props> = () => {
    return (
        <KContainer>
            <KSpace spaces={2}/>
            <Row>
                <Col xs={12}><Acquisitions reduced={false}/></Col>
            </Row>
        </KContainer>
    );
};