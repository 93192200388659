import {UserInfo, UserProfileData} from "../5.types/userstate";
import {apiCall, GetParams, HttpMethod} from "./index";
import {SessionStats} from "../5.types/sessionsstate";
import {FullUser, UserProvider} from "../5.types/adminstate";
import {GetListResponse} from "./sessions";
import {CHG_ENDPOINT} from "../6.utils/constants";

export interface GetUsersParams extends GetParams {
    firstname?: string;
    lastname?: string;
    email?: string;
    provider?: string;
}

export type GetUsersResponse = GetListResponse<FullUser>;

export async function isAuthenticated(): Promise<boolean> {
    const res = await apiCall({urlComponent: "users/current/isAuthenticated", ignoreAuthResponse: true});
    return res.ok;
}

export async function getCurrentUser(): Promise<UserInfo> {
    const res = await apiCall({urlComponent: "/users/current"});
    const json = await res.json();
    return {
        userId: json.userId,
        email: json.email,
        firstname: json.firstname,
        lastname: json.lastname,
        scopes: json.scopes,
        provider: json.provider || UserProvider.INTERNAL,
    };
}

export async function login(username: string, password: string): Promise<void> {
    await apiCall({urlComponent: "/login", method: HttpMethod.POST, body: {username, password}, ignoreAuthResponse: true});
}

export async function logout(): Promise<void> {
    await apiCall({urlComponent: "/logout", method: HttpMethod.POST});
}

export async function getStoreUrl(): Promise<string> {
    const res = await apiCall({urlComponent: `/users/current/storeUrl`});
    const json = await res.json();
    return json.url;
}

export async function getProfileUrl(): Promise<string> {
    const res = await apiCall({urlComponent: `/users/current/profileUrl`});
    const json = await res.json();
    return json.url;
}

export async function getStats(): Promise<SessionStats> {
    const res = await apiCall({urlComponent: "/users/current/statistics"});
    const json = await res.json();
    return {
        completed: json.sessions.closed,
        remaining: json.credits.available,
        used: json.credits.consumed,
        infinite: json.credits.infinite,
    };
}

function transformToUser(obj: any): FullUser {
    return {
        userId: obj.userId,
        email: obj.email,
        firstname: obj.firstname,
        lastname: obj.lastname,
        provider: obj.provider || UserProvider.INTERNAL,
        consumedCredits: obj.consumedCredits || 0,
        crmid: obj.crmid || "",
        externalId: obj.externalId || "",
    };
}

export async function getUsers(query: GetUsersParams): Promise<GetUsersResponse> {
    const res = await apiCall({urlComponent: "/users", query});
    const json = await res.json();
    return {
        results: json.results.map((u: any) => transformToUser(u)),
        total: json.total,
    };
}

export async function resendEmail() {
    await apiCall({
        urlComponent: `${CHG_ENDPOINT}/users/current:sendVerificationEmail`,
        method: HttpMethod.POST,
        contentType: "none",
    });
}

export async function resetPassword() {
    await apiCall({
        urlComponent: `${CHG_ENDPOINT}/users/current:resetPassword`,
        method: HttpMethod.POST,
        contentType: "none",
    });
}

export async function updateUserProfile(up: UserProfileData) {
    await apiCall({
        urlComponent: `${CHG_ENDPOINT}/users/current`,
        method: HttpMethod.PUT,
        body: up,
    });
}