import {connect} from "react-redux";
import {App, DispatchProps, StateProps, InnerProps} from "../1.components/App";
import {RootState, ThunkDispatch} from "../5.types";
import {cancelSession} from "../3.actions/sessions";
import {doGetUserInfo} from "../3.actions/thunks/users";

function mapStateToProps({sessions, user}: RootState): StateProps {
    return {
        session: sessions.newSession,
        openedModal: sessions.openedModal,
        authChecked: user.checked,
        userInfo: user.info,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onCancelSession: () => dispatch(cancelSession()),
        onGetUserInfo: (auth0User) => dispatch(doGetUserInfo(auth0User)),
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(App);