import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {UserState} from "../5.types/userstate";
import {checked, checking, setUserInfo, setUserProfileUrl, setUserStoreUrl} from "../3.actions/users";

const initialState: UserState = {
    checked: false,
};

export const usersReducer = createReducer(initialState)
    .handleAction(setUserInfo, (state, action) => update(state, {
        info: {$set: action.payload},
    }))
    .handleAction(setUserProfileUrl, (state, action) => state.info ? update(state, {
        info: {
            profileUrl: {$set: action.payload}
        }
    }) : state)
    .handleAction(setUserStoreUrl, (state, action) => state.info ? update(state, {
        info: {
            storeUrl: {$set: action.payload}
        }
    }) : state)
    .handleAction(checked, (state) => update(state, {
        checked: {$set: true},
    }))
    .handleAction(checking, (state) => update(state, {
        checked: {$set: false},
    }))
;
