import {FileCategory, SessionSimpleState, SessionState} from "../5.types/sessionsstate";

const it = {
    locale: {
        en: "EN",
        it: "IT",
    },
    generic: {
        yes: "Sì",
        no: "No",
    },
    user: {
        name: "Nome",
        email: "Email",
        verified: "Email verificata",
        modify: "Modifica",
        save: "Salva modifiche",
        resetPasswordMessage: "Un'email è stata inviata al tuo indirizzo con le istruzioni per cambiare la password",
        resendEmail: "invia nuovamente",
        resendEmailMessage: "Un'email è stata inviata al tuo indirizzo con le istruzioni per la verifica dell'account. Dopo aver verificato il tuo account, ricarica questa pagina",
    },
    home: {
        payoff: "Acquisizione forense delle prove su Internet",
        call: "Benvenuto su Web Forensics, per iniziare acquista dei crediti.",
        contactus: "Hai bisogno aiuto?",
        contactSubject: "Ho bisogno di aiuto con Web Forensics",
        contactBody: "Salve,\nAvremmo bisogno di aiuto con Web Forensics,\n",
        welcome: {
            title: "Benvenuto su Web Forensics",
            subtitle: "Alcune informazioni utili prima di iniziare",
            skip: "Salta",
            next: "Successivo",
            tip1: `Puoi accedere in ogni momento a questa guida contestuale cliccando sull'icona "i" nell’intestazione della prima card della dashboard.`,
            tip2: `Guarda la video guida per scoprire come utilizzare Web Forensics.`,
            tip3: `La dashboard è la pagina di atterraggio principale di Web Forensics, un cruscotto che ti consentirà di tenere sotto controllo i crediti residui, quelli già utilizzati e le sessioni di acquisizione forense completate. Ogni credito equivale ad una sessione della durata di 5 minuti, erogata a scatti anticipati. È possibile utilizzare più crediti in una singola sessione, fino ad un massimo di 18, per una durata complessiva di 90 minuti. Per avviarne una nuova clicca sul pulsante “Nuova sessione”`,
            tooltip: "Apri la finestra di benvenuto",
            gotodash: "Vai alla dashboard",
        }
    },
    error: {
        sessionCreation: "Error nella creazione delle sessione. Forse c'è una sessione già attiva.",
        pdfGeneration: "Errore nella generazione del PDF.",
        paymentRequired: "Non hai crediti disponibili per creare una nuova Sessione.",
        deleteSession: "Eliminazione della session non riuscita correttamente",
        login: "Username o password errate",
        page: "Errore",
        notFound: "Pagina non trovata",
        forbidden: "Accesso negato",
        admin: {
            refund: "Non è stato possibile di rimborsare la sessione",
            terminate: "Non è stato possibile di cambiare lo stato della sessione al corrispettivo terminato",
            changeOwner: "Non è stato possibile di cambiare il proprietario della sessione",
            stop: "Non è stato possibile di cambiare lo stato della sessione al corrispettivo fermato",
            fail: "Non è stato possibile di fare fallire la sessione",
            pdfDeletion: "Il PDF non è stato cancellato, forse non era mai stato generato",
        },
    },
    bar: {
        sessions: "Dashboard",
        mysessions: "Gestione sessioni",
        account: "Account",
        createbutton: "Nuova sessione",
        cancelbutton: "Annulla",
        logout: "Disconnetti",
        profile: "Profilo",
        login: "Accedi",
        loginInternal: "Acceso Interno",
        admin: "Amministrazione",
        adminUsers: "Utenti",
        adminSessions: "Sessioni",
        buy: "Acquista",
        support: "Supporto",
    },
    new: {
        title: "Nuova Sessione di Acquisizione",
        namelabel: "Nome della sessione",
        namerequired: "Il nome della sessione è obbligatorio",
        patternError: "Caratteri validi: <b>A-Z</b>, <b>a-z</b>, <b>0-9</b> e <b>_(),;.+-</b>",
        defaultName: "Nome di nuova sessione",
        customization: "Personalizzazione della relazione metodologica",
        subtitlelabel: "Sottotitolo",
        subtitlehint: "Questo sottotitolo verrà visualizzato nella pagina iniziale sotto la data (max 100 caratteri)",
        logolabel: "Carica il logo",
        logohint: "Questo logo verrà visualizzato nella pagina iniziale. Se non viene caricato verrà utilizzato il logo di Web Forensics (max 1MB)",
        colorlabel: "Colore principale",
        colorhint: "Questo colore verrà utilizzato nella striscia della pagina iniziale e nei titoli delle sezioni. Non può essere troppo chiaro, altrimenti i testi non saranno leggibili.",
        logoMaxSize: "Dimensione dell'immagine non valida. Dimensione massima consentita: 1MB",
        colorError: "Colore troppo luminoso",
        subtitleError: "Sottotitolo troppo lungo",
        startButton: "Crea Sessione",
        attention: "Attenzione",
        minCompatibilities: "Minimum requirements",
        recommended: "Browser consigliati",
        yourBrowser: "Browser corrente",
        minBrowser: "Con questo browser potrebbero non essere disponibili funzionalitià come: copy/paste.",
        notCompatible: "Il tuo browser non soddisfa i requisiti minimi di compatibilità.",
        subtitle: "Per un'esperienza ottimale aggiorna il browser con uno dei consigliati.",
        continue: "Sicuro di voler procedere con la sessione?",
        yes: "Sì",
        no: "No",
        all: "Tutti",
        leave: "Abbandonare la pagina?<br/><br/><b>La sessione non verrà interrotta e rimarrà accessibile dalla Dashboard, ma continuerà a consumare crediti.</b><br/><br/>Per interrompere la sessione utilizza il pulsante \"Ferma e archivia\" nell'ambiente di acquisizione.",
        close: "Chiudi",
        connectionError: "Il collegamento non è ancora pronto o c'è stato un errore di collegamento",
        retry: "Ricollegamento automatico tra %{seconds} secondi",
        retryButton: "Retry",
        reset: "Reset",
        errorMessage: "Il sistema sta impiegando più tempo del previsto per caricare l'ambiente di acquisizione. Verifica che la tua connessione sia stabile e riprova.",
        waitingMessage: "Potrebbero essere necessari fino a 3 minuti per inizializzare l'ambiente di acquisizione, ti preghiamo di rimanere in attesa senza chiudere o fare il refresh della pagina.<br/> Puoi annullare in qualunque momento.",
        tips: {
            1: "L'ambiente di acquisizione è una macchina virtuale preconfigurata che garantisce all’utente di lavorare in un sistema isolato ed estremamente sicuro.",
            2: "La qualità audio e video della registrazione dipendono dalla velocità di connessione e potrebbero non essere ottimali. Una leggera latenza non inficerà la prova.",
            3: "L’ambiente di acquisizione rappresenta nella sua interezza la porzione di interfaccia che sarà registrata come video della sessione all’interno del pacchetto di evidenza forense.",
            4: "Uno o più screenshot dei momenti salienti della sessione potrebbero tornare utili come elementi di prova da fornire al CTU o alla controparte, assieme al video della sessione, alla relazione metodologica e al pacchetto di evidenza forense.",
            5: "Le console di log visualizzano in tempo reale informazioni tecniche, URL visitati ed eventuali azioni compiute dal sistema sottostante. Mostrare tali informazioni è un aspetto fondamentale per rispettare la proprietà di ridondanza della prova forense, così da limitare al massimo le possibili contestazioni.",
            6: "Il file ZIP creato durante la sessione dovrà essere prodotto in giudizio così com’è, senza essere aperto. La memoria predisposta per l’occasione dovrà includere la relazione metodologica, che fornirà al CTU e alla controparte gli elementi essenziali per poter valutare correttamente la prova.",
        },
        discover: "Scopri Web Forensics",
    },
    stats: {
        title: "Panoramica",
        remaining: "Crediti rimanenti",
        used: "Crediti utilizzati",
        completed: "Sessioni completate",
        buy: "Acquista crediti",
        remainingtime: "Tempo di acquisizione rimanente",
        nomorepart1: "Sembra che non ti rimangano più crediti a disposizione,",
        nomorepart2: "aggiungi più crediti ",
        nomorepart3: "per cominciare una Nuova Sessione",
        more: "Aggiungi crediti. Contatta l’agenzia di zona",
        moreWF: "Aggiungi crediti",
    },
    details: {
        titleSession: "Dettagli",
        titleFiles: "Elenco file",
        name: "Nome",
        created: "Data di creazione",
        elapsed: "Durata",
        state: "Stato",
        download: "Scarica pacchetto",
        previewNoFile: "Seleziona un file",
        deleted: "Eliminata",
        messagePCAP: "Il file PCAP contiene il traffico di rete generato durante la navigazione. Può essere visualizzato utilizzando un software come, ad esempio, <a href='https://www.wireshark.org/' target='_blank'>WireShark</a>",
    },
    fileBrowser: {
        name: "Nome",
        type: "Tipo",
        size: "Dimensione",
        created: "Data di Creazione",
        partialDownload: "Scarica le categorie di file selezionate",
        [FileCategory.SCREENSHOT]: "Screenshots",
        [FileCategory.DOWNLOAD]: "Downloads",
        [FileCategory.LOG]: "Logs",
        [FileCategory.VIDEO]: "Video",
        [FileCategory.PCAP]: "PCAP",
        [FileCategory.KEYS]: "Chiavi",
        [FileCategory.XML]: "XML",
        [FileCategory.MHTML]: "Sorgente e risorce",
        [FileCategory.WACZ]: "Archivio WACZ",
    },
    login: {
        username: "Nome utente",
        usernamerequired: "Il nome utente è obbligatorio",
        password: "Password",
        passwordrequired: "La password è obbligatoria",
        button: "Accedi",
        lostPassword: "Password dimenticata?",
        internal: "Accedi come interno",
    },
    acquisitions: {
        titleComplete: "Sessioni",
        titleLastFive: "Ultime sessioni",
        sessionDeleted: "Sessione eliminata correttamente",
        table: {
            name: "Nome",
            created: "Data di creazione",
            urls: "Totale Url",
            state: "Stato",
            actions: {
                pdf: "PDF",
                pdftip: "Scarica la relazione metodologica",
                pcap: "ZIP",
                pcaptip: "Scarica il pacchetto di evidenza forense",
                pcapAlert: `
                    Questo pacchetto di evidenza forense dovrà essere prodotto in giudizio così com’è, senza essere aperto. La memoria predisposta per l’occasione dovrà includere la relazione metodologica, che fornirà al CTU e alla controparte gli elementi essenziali per poter valutare correttamente la prova.
                    <br/><br/>
                    Si può scaricare il pacchetto oppure condividere il seguente link firmato. Il link rimarrà valido per 7 giorni:
                    <br>
                    <div class="container archive-link">
                        <div class="row">
                            <div class="col-xs-2 col-2" style="width: auto; padding: 0;">
                                <div id="archive-copy-button" class="copy" style="margin-top: 20px; margin-right: 5px; cursor:pointer;"
                                onclick="
                                    var copyText = document.getElementById('archive-link');
                                    copyText.select();
                                    copyText.setSelectionRange(0, 99999);
                                    navigator.clipboard.writeText(copyText.value);
                                    var archiveCopyButton = document.getElementById('archive-copy-button');
                                    archiveCopyButton.setAttribute('class', 'copied');        
                                    var archiveCopyText = document.getElementById('archive-copy-text');
                                    archiveCopyText.innerHTML = 'COPIATO';
                                    setTimeout(function(){ archiveCopyButton.setAttribute('class', 'copy')}, 3000);
                                    setTimeout(function(){ archiveCopyText.innerHTML = 'COPIA'}, 3000);
                                ">
                                <i class="fal fa-copy" ></i> <span id="archive-copy-text" style="font-size:11px;">COPIA</span></div>
                            </div>
                            <div class="col-xs-10 col-10 k-input-group">
                                <input readonly id="archive-link" style="width:100%; margin-top: 10px;" value="%{link}" class="k-input form-control"/>
                            </div>
                        </div>
                    </div>
                `,
                pcapAlertButtonYes: "Scarica",
                pcapAlertButtonNo: "Chiudi",
                view: "Apri",
                viewtip: "Dettagli dell'acquisizione forense",
                abort: "Annulla",
                aborttip: "Annulla questa sessione senza consumare alcun credito",
                retry: "Riprova",
                retrytip: "Una nuova sessione verrà creata con lo stesso nome",
                del: "Elimina",
                deltip: "Elimina la sessione",
                enter: "Riprendi",
                entertip: "Riprendi la sessione",
            },
        },
        stateValues: {
            [SessionSimpleState.READY]: "Pronta",
            [SessionSimpleState.INITIALIZING]: "In inizializzazione",
            [SessionSimpleState.STARTED]: "Cominciata",
            [SessionSimpleState.FAILED]: "Fallita",
            [SessionSimpleState.COMPLETED]: "Completata",
            [SessionSimpleState.FINALIZING]: "In elaborazione",
            [SessionSimpleState.ABORTED]: "Abortita",
        },
        warnTitle: "Sessioni in esecuzione",
        warnDescription: "Le seguenti sessioni sono ancora <b>in esecuzione</b> e stanno potenzialmente consumando crediti. <br/>Prosegui le acquisizioni cliccando sul pulsante <b>RIPRENDI</b>. <br/>Se la sessione ha una durata inferiore a 5 minuti potrà essere annullata <b>gratuitamente</b>.",
    },
    date: {
        short: "DD/MM/YYYY",
        long: "DD/MM/YYYY HH:mm",
    },
    kptable: {
        filters: "Filtri",
        apply: "Applica",
        show_columns: "Mostra colonne",
        pager: {
            first: "Prima",
            last: "Ultima",
        },
        actions: "Azioni",
        date: {
            short: "DD/MM/YYYY",
            long: "DD/MM/YYYY HH:mm",
        },
        confirm: {
            yes: "Sì",
            no: "No",
        },
        show: "Mostra",
        globalSelectionWarn: "Questa selezione solo si applica per i %{count} elementi visibili",
    },
    admin: {
        users: {
            title: "Utenti",
            table: {
                email: "Email",
                name: "Nome",
                lastname: "Cognome",
                actionOpenSessions: "Apri sessioni",
                provider: "Provider",
                consumedCredits: "Crediti",
                crmid: "crmid",
                externalId: "EID",
            },
        },
        sessions: {
            title: "Sessioni",
            missing: "Manca la selezione dell'utente, ",
            back: "torna alla selezione",
            table: {
                name: "Nome",
                created: "Data di creazione",
                state: "Stato",
                start: "Data inizio",
                end: "Data fine",
                signingDate: "Data firma",
                lastHeartBeat: "Ultimo heartbeat",
                id: "Id",
                consumedCredits: "Crediti",
                elapsed: "Durata",
                softDeleted: "Eliminata",
                actions: {
                    refund: "Rimborso completo",
                    refundOne: "Rimborso di un credito",
                    changeOwner: "Cambio Utente",
                    terminate: "Termina",
                    stop: "Ferma",
                    fail: "Fallisci",
                    deletePdf: "Resetta PDF",
                    deletePdftip: "Cancella i PDF pregenerati, la prossima volta che l'utente provi a scaricarli, questi verrano rigenerati",
                    refundtip: "Rimborsa questa sessione all'utente",
                    refundOneTip: "Rimborsa un credito di questa sessione all'utente",
                    changeOwnertip: "Cambia l'utente proprietario di questa sessione",
                    terminatetip: "Termina la VM di questa sessione",
                    stoptip: "Ferma la VM di questa sessione",
                    failtip: "Forza il fallimento di questa sessione",
                    del: "Elimina",
                    deltip: "Elimina la sessione",
                },
            },
            msg: {
                refund: "Sessione rimborsata",
                refundOne: "Credito rimborsato",
                changeOwner: "Proprietario della sessione cambiato con successo",
                terminate: "Stato della sessione cambiato al corrispettivo terminato",
                stop: "Stato della sessione cambiato al corrispettivo fermato",
                fail: "Sessione forzata a fallire",
                del: "Sessione eliminata",
            },
            stateValues: {
                [SessionState.VM_READY]: "Pronta",
                [SessionState.VM_ABORTED_TIMEOUT_TERMINATED]: "Abortita per Timeout e Terminata",
                [SessionState.VM_ABORTED_ERROR_TERMINATED]: "Abortita con Errore e Terminata",
                [SessionState.VM_ABORTED_MANUAL_TERMINATED]: "Abortita Manualmente e Terminata",
                [SessionState.ARTIFACTS_UPLOADED]: "Tutti File caricati",
                [SessionState.VM_ABORTED_MANUAL]: "Abortita Manualmente",
                [SessionState.GURU_INTERVENTION_STOPPED]: "In attesa intervento GURU e fermata",
                [SessionState.FATAL_FAILED_TERMINATED]: "Fallita e terminata",
                [SessionState.VM_PREPARING]: "In inizializzazione",
                [SessionState.VM_ABORTED_ERROR]: "Abortita con Errore",
                [SessionState.GURU_INTERVENTION]: "In attesa intervento GURU",
                [SessionState.CLOSED]: "Chiusa",
                [SessionState.CLOSING]: "In elaborazione",
                [SessionState.FATAL_FAILED]: "Fallita",
                [SessionState.STARTED]: "Cominciata",
                [SessionState.VM_ABORTED_TIMEOUT]: "Abortita per Timeout",
                [SessionState.VM_TERMINATED]: "Completata e terminata",
            },
            coModal: {
                title: "Cambia proprietario per sessione",
                closeButton: "Chiudi",
                changeButton: "Cambia",
                selectRequired: "Selezionare un utente"
            },
        },
    },
};
export default it;
