import {connect} from "react-redux";
import {DetailBrowser, DispatchProps, StateProps, InnerProps} from "../1.components/DetailBrowser";
import {RootState, ThunkDispatch} from "../5.types";
import {doDownloadFile, doGetSessionDetail} from "../3.actions/thunks/sessions";
import {isAdmin} from "../6.utils/commons";

function mapStateToProps({sessions, user}: RootState): StateProps {
    return {
        detail: sessions.detail,
        isAdmin: isAdmin(user.info),
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onGetSession: (id) => dispatch(doGetSessionDetail(id)),
        onDownloadFile: (session, file) => dispatch(doDownloadFile(session, file)),
    };
}

export default connect<StateProps, DispatchProps, InnerProps, RootState>(mapStateToProps, mapDispatchToProps)(DetailBrowser);