import * as React from "react";
import {ReactChild, useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {I18n, Translate} from "react-redux-i18n";
import {
    getPossibleStates,
    Session,
    SessionFinalStates,
    SessionSimpleState,
    SessionState,
    SessionStateMap,
    SimpleSessionStateMap
} from "../5.types/sessionsstate";
import {WASpinner} from "./WASpinner";
import {routerTools} from "../6.utils/router";
import {createLinkToDownload, formatNumber, tableGetParams, transformToSessionParams} from "../6.utils/commons";
import {getApiUrl} from "../7.api";
import {
    ActionType,
    Column,
    confirmBox,
    DataType,
    FixedSelectionFilter,
    FreeTextFilter,
    GetParams,
    GlobalActionType,
    KCard,
    KModal,
    KModalBackdrop,
    KModalSize,
    KModalVariant,
    KTableLoader,
    Table
} from "@kopjra/uikit";
import {Feature, hasFeature} from "../6.utils/features";
import {getToken} from "./App";
import {translations} from "../9.locale";
import moment from "moment/moment";
import {LongPoller} from "../6.utils/LongPoller";
import {getSessions} from "../7.api/sessions";

export interface StateProps {
    sessions?: Session[];
    locale: string;
    total: number;
}

export interface DispatchProps {
    onGetSessions: (query: GetParams, tableName?: string) => Promise<void>;
    onCleanSessions: () => void;
    onSetSession: (session: Session) => void;
    onDownloadReport: (session: Session, locale: string) => Promise<void>;
    onAbort: (sessionId?: string | number) => void | Promise<void>;
    onRetry: (session?: Session) => void | Promise<void>;
    onDeleteSession: (session: Session) => Promise<void>;
    onDoCreateArchiveLink: (session: Session) => Promise<string>;
}

export interface InnerProps {
    reduced?: boolean
}

export type Props = StateProps & DispatchProps & InnerProps;

export const Acquisitions: React.FC<Props> = ({onDoCreateArchiveLink, onDeleteSession, onCleanSessions, sessions, total, onGetSessions, onSetSession, reduced = false, locale, onDownloadReport, onRetry, onAbort}) => {
    const [sSessions, setSSessions] = useState<Session[] | undefined>();
    const [forceHide, setForceHide] = useState(false);
    const query: GetParams = {
        top: 5,
        skip: 0,
    };
    const tableName = `sessions/list/${reduced ? "reduced" : "full"}`;

    if (reduced) {
        query.filter = [{name: "state", value: getPossibleStates([SessionSimpleState.FAILED], true).join(",")}];
        query.sort = "-updated";
    }
    const sessionRetriever = async (innerQuery: GetParams, fixedQuery?: GetParams) => {
        await onGetSessions(fixedQuery ? fixedQuery : innerQuery);
    };

    const startedSessions = async () => getSessions(transformToSessionParams({filter: [{name: "state", value: SessionState.STARTED}]}));
    useEffect(() => {
        onCleanSessions();
        onGetSessions(reduced ? query : tableGetParams(tableName)).catch((e) => console.warn("Get Sessions error", e));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onGetSessions]);
    useEffect(() => {
        const poller = new LongPoller(async () => setSSessions((await startedSessions()).results), 5000);
        if (!sSessions) {
            (async () => {
                setSSessions((await startedSessions()).results);
            })().catch(console.warn);
        } else {
            poller.start();
        }
        return () => poller && poller.stop();
        // eslint-disable-next-line
    }, [startedSessions, onGetSessions])

    const actions: ActionType<Session>[] = [
        {
            name: <><i className="fal fa-arrow-right-to-bracket action-icon"/> <Translate value="acquisitions.table.actions.enter"/></>,
            handler: async (datum: Session) => onSetSession(datum),
            shouldRender: (datum: Session) => (SessionStateMap[datum.state] === SessionSimpleState.READY || SessionStateMap[datum.state] === SessionSimpleState.STARTED) && !!datum.connection,
            tooltip: <Translate value="acquisitions.table.actions.entertip"/>,
        },
        {
            name: <><i className="fal fa-eye action-icon"/> <Translate value="acquisitions.table.actions.view"/></>,
            handler: async (datum: Session) => {routerTools.push(`/sessions/${datum.id}`)},
            shouldRender: (datum: Session) => SessionStateMap[datum.state] === SessionSimpleState.COMPLETED,
            tooltip: <Translate value="acquisitions.table.actions.viewtip"/>,
        },
        {
            name: <><i className="fal fa-times action-icon"/> <Translate value="acquisitions.table.actions.abort"/></>,
            handler: async (datum: Session) => onAbort(datum.id),
            confirmation: true,
            shouldRender: (datum: Session) => SessionStateMap[datum.state] === SessionSimpleState.INITIALIZING || SessionStateMap[datum.state] === SessionSimpleState.READY || (SessionStateMap[datum.state] === SessionSimpleState.STARTED && datum.consumedCredits <= 1),
            tooltip: <Translate value="acquisitions.table.actions.aborttip"/>,
        },
        {
            name: <><i className="fal fa-redo action-icon"/> <Translate value="acquisitions.table.actions.retry"/></>,
            handler: async (datum: Session) => onRetry(datum),
            confirmation: true,
            shouldRender: (datum: Session) => SessionStateMap[datum.state] === SessionSimpleState.ABORTED,
            tooltip: <Translate value="acquisitions.table.actions.retrytip"/>,
        },
        {
            name: <><i className="fal fa-file-pdf action-icon"/> <Translate value="acquisitions.table.actions.pdf"/></>,
            items: Object.keys(translations).map((locale) => ({
                name: <Translate value={`locale.${locale as "en" | "it"}`}/>,
                value: locale,
            })),
            handler: (datum: Session, locale) => onDownloadReport(datum, locale || "en"),
            shouldRender: (datum: Session) => SessionStateMap[datum.state] === SessionSimpleState.COMPLETED,
            tooltip: <Translate value="acquisitions.table.actions.pdftip"/>,
        },
        {
            name: <><i className="fal fa-arrow-to-bottom action-icon"/> <Translate value="acquisitions.table.actions.pcap"/></>,
            handler: async (datum: Session) => {
                let link= "";
                try {
                    link = await onDoCreateArchiveLink(datum);
                } catch (e) {
                    console.log(e);
                }
                if (await confirmBox({
                    message: I18n.t("acquisitions.table.actions.pcapAlert", {link}),
                    yesText: `<i class="fa fa-download"></i> <span>${I18n.t("acquisitions.table.actions.pcapAlertButtonYes")}</span>`,
                    noText: `<i class="fa fa-times"></i> <span>${I18n.t("acquisitions.table.actions.pcapAlertButtonNo")}</span>`,
                    size: "large",
                })) {
                    let query: any = undefined;
                    if (hasFeature(Feature.AUTH0)) {
                        try {
                            const token = await getToken();
                            if (token) {
                                query = {token};
                            }
                        } catch (_err) {
                            /* noop */
                        }
                    }
                    createLinkToDownload(`session_${datum.id}`, getApiUrl({urlComponent: `sessions/${datum.id}/files/archive`, query}));
                }
            },
            shouldRender: (datum: Session) => SessionStateMap[datum.state] === SessionSimpleState.COMPLETED,
            tooltip: <Translate value="acquisitions.table.actions.pcaptip"/>,
        },
        {
            name: <><i className="fal fa-trash action-icon"/> <Translate value="acquisitions.table.actions.del"/></>,
            handler: async (datum: Session) => {
                await onDeleteSession(datum);
                onGetSessions(reduced ? query : tableGetParams(tableName), tableName).catch(console.log);
            },
            confirmation: true,
            shouldRender: (datum: Session) => SessionFinalStates.indexOf(datum.state) !== -1,
            tooltip: <Translate value="acquisitions.table.actions.deltip"/>,
        },
    ];

    const globalActions: GlobalActionType[] = [
        {
            bulk: false,
            lefty: false,
            handler: async () => {onGetSessions(reduced ? query : tableGetParams(tableName), tableName).catch(console.log)},
            name: <i className="fal fa-sync action-icon"/>,
        },
    ];


    function renderSessionName(datum: object): ReactChild {
        const session = datum as Session;
        if (session.connection && (SessionStateMap[session.state] === SessionSimpleState.READY || SessionStateMap[session.state] === SessionSimpleState.STARTED)) {
            return (
                <a href="/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {e.preventDefault(); onSetSession(session)}}>{session.name}</a>
            );
        } else {
            return session.name;
        }
    }

    function renderElapsed(datum: object): ReactChild {
        const session = datum as Session;
        if (session.start) {
            const elapsed = moment.duration(moment(new Date()).diff(moment(session.start)));
            return `${formatNumber(Math.floor(elapsed.asHours()))}:${formatNumber(elapsed.minutes())}:${formatNumber(elapsed.seconds())}`;
        } else {
            return "";
        }
    }

    function renderSessionState(datum: object): ReactChild {
        const session = datum as Session;
        return <Translate value={`acquisitions.stateValues.${SessionStateMap[session.state]}`}/>
    }

    return <> {(reduced && sessions && sessions.length === 0)
        ? null : (
            <KCard header={<Translate value={`acquisitions.title${reduced ? "LastFive" : "Complete"}`}/>}>
                {sessions ? (
                    <Row style={{marginTop: 0}}>
                        <Col>
                            <Table
                                checkboxes={false}
                                filterDefinition={[
                                    new FreeTextFilter("name", I18n.t("acquisitions.table.name")),
                                    new FixedSelectionFilter("state", I18n.t("acquisitions.table.state"), Object.keys(SimpleSessionStateMap).map((key) => ({
                                        label: key,
                                        value: SimpleSessionStateMap[key].join(","),
                                    })))
                                ]}
                                globalActions={globalActions}
                                globalWaiter={<WASpinner size={200}/>}
                                waiter={<WASpinner size={100} variant="dark"/>}
                                id={tableName}
                                total_count={total}
                                loaderFunc={(q: GetParams) => sessionRetriever(q, reduced ? query : undefined)}
                                loadInterval={10000}
                                data={sessions}
                                hideColumnSelector={reduced === true}
                                hideFilters={reduced === true}
                                keyField={"id"}
                                hidePager={reduced === true}
                            >
                                <Column colid="name" name="acquisitions.table.name" render={renderSessionName} type={DataType.GENERIC} sort={!reduced ? "name" : undefined} colspan={3} classes={"text-start"}/>
                                <Column colid="created" name="acquisitions.table.created" type={DataType.DATE} sort={!reduced ? "created" : undefined} colspan={2}/>
                                <Column colid="state" name="acquisitions.table.state" render={renderSessionState} type={DataType.GENERIC} sort={!reduced ? "state" : undefined} colspan={3} classes={"text-end"}/>
                                <Column colid="actions" type={DataType.ACTIONS} actions={actions} colspan={4} classes="text-end"/>
                            </Table>
                        </Col>
                    </Row>
                ) : (
                    <Row style={{marginTop: 40, marginBottom: 30}}>
                        {/*<Col className="text-center">*/}
                        {/*<WASpinner size={200} variant="light"/>*/}
                        {/*</Col>*/}
                        <KTableLoader/>
                    </Row>
                )}
            </KCard>
        )}
        <KModal show={!forceHide && !!sSessions && sSessions.length > 0} size={KModalSize.lg} variant={KModalVariant.warning} header={<Translate value={"acquisitions.warnTitle"}/> } onHide={() => setForceHide(true)} backdrop={KModalBackdrop.static}>
            <p className="medium"><Translate value={"acquisitions.warnDescription"} dangerousHTML={true}/></p>
            <div className="small">
                <Table
                    id={"startedSessionsTable"}
                    data={sSessions || []}
                    total_count={5}
                    hidePager={true}
                    hideColumnSelector={true}
                    hideFilters={true}
                    checkboxes={false}
                    keyField={"id"}
                    loaderFunc={async () => setSSessions((await startedSessions()).results)}
                >
                    <Column colid="name" name="acquisitions.table.name" type={DataType.GENERIC} sort={undefined} colspan={3} classes={"text-start"}/>
                    <Column colid="created" name="acquisitions.table.created" type={DataType.DATE} sort={undefined} colspan={2}/>
                    <Column colid="elapsed" name="admin.sessions.table.elapsed" render={renderElapsed} type={DataType.GENERIC} sort={undefined} classes={"text-center"} colspan={1}/>
                    <Column colid="consumedCredits" name="admin.sessions.table.consumedCredits" type={DataType.GENERIC} sort={undefined} classes={"text-end"} colspan={1}/>
                    <Column colid="actions" type={DataType.ACTIONS} actions={[
                        {
                            name: <><i className="fal fa-arrow-right-to-bracket action-icon"/> <Translate value="acquisitions.table.actions.enter"/></>,
                            handler: async (datum: Session) => {
                                $(".kapp").removeClass("blur");
                                onSetSession(datum);
                                setForceHide(true);
                            },
                            tooltip: <Translate value="acquisitions.table.actions.entertip"/>,
                        },
                        {
                            name: <><i className="fal fa-times action-icon"/> <Translate value="acquisitions.table.actions.abort"/></>,
                            handler: async (datum: Session) => {onAbort(datum.id); sSessions?.splice(sSessions?.findIndex((value) => value.id === datum.id), 1); setSSessions(sSessions);},
                            confirmation: true,
                            shouldRender: (datum: Session) => SessionStateMap[datum.state] === SessionSimpleState.INITIALIZING || SessionStateMap[datum.state] === SessionSimpleState.READY || (SessionStateMap[datum.state] === SessionSimpleState.STARTED && datum.consumedCredits <= 1),
                            tooltip: <Translate value="acquisitions.table.actions.aborttip"/>,
                        },
                    ]} colspan={4} classes="text-end"/>
                </Table>
            </div>
        </KModal>
    </>;
};
