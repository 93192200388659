import {combineReducers, Reducer} from "redux";
import {sessionsReducer} from "./sessions";
import {i18nReducer, I18nState} from "react-redux-i18n";
import {usersReducer} from "./users";
import {adminReducer} from "./admin";
import {kAlertReducer, kptableReducer } from "@kopjra/uikit";

export const rootReducer = combineReducers({
    i18n: i18nReducer as unknown as Reducer<I18nState>,
    alert: kAlertReducer,
    kptable: kptableReducer,
    sessions: sessionsReducer,
    user: usersReducer,
    admin: adminReducer,
});