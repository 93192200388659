import {User} from "./adminstate";

export enum AuthScope {
    "ADMIN" = "wf:admin",
    "SUPERADMIN" = "wf:superadmin",
    "PFD_CREATE" = "wf:pdf_create",
}

export interface UserInfo extends User {
    scopes: AuthScope[];
    picture?: string;

    storeUrl?: string;
    profileUrl?: string;
}

export interface UserState {
    checked: boolean;
    info?: UserInfo;
}

export interface UserProfileData {
    name?: string;
}