import {createAction} from "typesafe-actions";
import {UserInfo} from "../5.types/userstate";
import {GetUsersResponse} from "../7.api/users";

export const setUserInfo = createAction("users/GOT_INFO", (userInfo?: UserInfo) => userInfo)();
export const gotUsers = createAction("users/GOT_USERS", (response: GetUsersResponse) => response)();
export const cleanUsers = createAction("users/CLEAN")();
export const checked = createAction("users/CHECKED")();
export const checking = createAction("users/CHECKING")();
export const setUserStoreUrl = createAction("user/SET_STORE_URL", (storeUrl: string) => storeUrl)();
export const setUserProfileUrl = createAction("user/SET_PROFILE_URL", (profileUrl: string) => profileUrl)();