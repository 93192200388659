import * as React from "react";
import {useState} from "react";
import {Alert, Col, Row} from "react-bootstrap";
import {I18n, Translate} from "react-redux-i18n";
import logoLight from "../8.resources/imgs/wf_icon_small_light.svg";
import {FetchError} from "../7.api";
import {useLocation,} from "react-router-dom";
import {LocationState} from "../5.types";
import {Feature, hasFeature} from "../6.utils/features";
import {KButton, KBUTTON_SIZE, KBUTTON_TYPE, KBUTTON_VARIANT, KContainer, KForm, KInput, KSpace, KSpinner} from "@kopjra/uikit";

export interface StateProps {
}

export interface DispatchProps {
    onLogin: (username: string, password: string, from?: string) => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const Login: React.FC<Props> = ({onLogin}) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [logging, setLogging] = useState(false);
    const [loginError, setLoginError] = useState(undefined);
    const location = useLocation<LocationState>();

    function handleSubmit() {
        setLogging(true);
        onLogin(username, password, location.state && location.state.from).catch((err) => {
            setLogging(false);
            setLoginError((err instanceof FetchError) ? ((err.response.status === 401) ? I18n.t("error.login") : err.response.statusText) : err.message);
        });
    }

     return (
        <KContainer>
            <Row style={{paddingTop: 80}}>
                <Col className="text-center">
                    <img alt="logo" src={logoLight} height={110}/>
                </Col>
            </Row>
            <Row style={{marginTop: 60}}>
                <Col className="text-center" md={{span: 4}} xs={12}>
                    <Alert show={!!loginError} dismissible={true} onClose={() => setLoginError(undefined)} variant="danger">
                        {loginError}
                    </Alert>
                    <KForm onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                                <KInput id={"username"} value={username} onChange={event => setUsername(event.currentTarget.value)}
                                        placeholder={I18n.t("login.username")} required={true} disabled={logging}/>
                            </Col>
                        </Row>
                        <KSpace/>
                        <Row>
                            <Col>
                                <KInput id={"password"} value={password} onChange={event => setPassword(event.currentTarget.value)}
                                        placeholder={I18n.t("login.password")} required={true} disabled={logging} type={"password"}/>
                            </Col>
                        </Row>
                        {hasFeature(Feature.GFL) ? (
                            <Row style={{marginTop: 5}}>
                                <Col className="text-end">
                                    <KButton text={<Translate value="login.lostPassword"/>}
                                             variant={KBUTTON_VARIANT.link}
                                             size={KBUTTON_SIZE.xs}
                                             onClick={() => {window.open("https://clienti.giuffre.it/UserManager/ResetPassword.aspx?prod=155", "_blank");}}
                                    />
                                </Col>
                            </Row>
                        ) : null}
                        {hasFeature(Feature.AUTH0) ? (
                            <Row style={{marginTop: 5}}>
                                <Col className="text-end">
                                    <KButton text={""}
                                             variant={KBUTTON_VARIANT.link}
                                             onClick={() => { window.open("/", "_blank"); }}
                                     />
                                </Col>
                            </Row>
                        ) : null}
                        <Row style={{marginTop: 11}}>
                            <Col>
                                <KButton text={logging ? <KSpinner/> : <><i className="fal fa-lg fa-sign-in-alt"/>&nbsp;&nbsp;<Translate value={hasFeature(Feature.AUTH0) ? "login.internal" : "login.button"}/></>}
                                         variant={KBUTTON_VARIANT.primary}
                                         type={KBUTTON_TYPE.submit}
                                         disabled={logging}
                                         fill={true}
                                />
                                {/*<Button variant="primary" className="loginButton" type="submit" disabled={logging}>*/}
                                {/*    {logging ? (*/}
                                {/*        <i className="fal fa-lg fa-circle-notch fa-spin"/>*/}
                                {/*    ) : (*/}
                                {/*        <><i className="fal fa-lg fa-sign-in-alt"/>&nbsp;&nbsp;<Translate value={hasFeature(Feature.AUTH0) ? "login.internal" : "login.button"}/></>*/}
                                {/*    )}*/}
                                {/*</Button>*/}
                            </Col>
                        </Row>
                    </KForm>
                </Col>
            </Row>
        </KContainer>
    );
};
