import {FileCategory, SessionSimpleState, SessionState} from "../5.types/sessionsstate";

const en = {
    locale: {
        en: "EN",
        it: "IT",
    },
    generic: {
        yes: "Yes",
        no: "No",
    },
    user: {
        name: "Name",
        email: "Email",
        verified: "Email verified",
        modify: "Modify",
        save: "Save changes",
        resetPasswordMessage: "An email was sent to your email address with instructions to change your password",
        resendEmail: "Resend",
        resendEmailMessage: "An email was sent to your email address with instructions to verify your account. After verifying it, reload this page",
    },
    home: {
        payoff: "Forensic acquisitions of evidences on the Internet",
        call: "Welcome to Web Forensics, to get started buy some credits.",
        contactus: "Need any help?",
        contactSubject: "Need help with Web Forensics",
        contactBody: "Hello,\nWe would like some help with Web Forensics,\n",
        welcome: {
            title: "Welcome to Web Forensics",
            subtitle: "Some tips to get started",
            skip: "Skip",
            next: "Next",
            tip1: `You can access this guide anytime by clicking on the icon "i" in the header of the first card of the dashboard.`,
            tip2: `Watch the video guide to discover how to use Web Forensics.`,
            tip3: `The dashboard is the landing page of Web Forensics, it will allow you to keep an eye on remaining credits, used credits and the completed acquisizione sessions. Each credit is equivalent to a 5-minute session, delivered in advance increments. Multiple credits can be used in a single session, up to a maximum of 18, for a total duration of 90 minutes. To start a new one click on the "New Session" button.`,
            tooltip: "Open the welcome window",
            gotodash: "Go to dashboard",
        }
    },
    error: {
        sessionCreation: "Session creation error. Maybe there is an active session.",
        pdfGeneration: "Error generating PDF.",
        paymentRequired: "No available credits to create a new Session.",
        deleteSession: "Error deleting the session.",
        login: "Wrong username or password",
        page: "Error",
        notFound: "Page not found",
        forbidden: "Access denied",
        admin: {
            refund: "Session couldn't be refunded",
            terminate: "Session couldn't be transitioned to the respective terminated state",
            changeOwner: "Owner couldn't be changed for this session",
            stop: "Session couldn't be transitioned to the respective stopped state",
            fail: "Session couldn't be forced to fail",
            pdfDeletion: "PDF could not be delete, maybe it was never generated in the first place",
        },
    },
    bar: {
        sessions: "Dashboard",
        mysessions: "manage sessions",
        account: "Account",
        createbutton: "New session",
        cancelbutton: "Cancel",
        logout: "Logout",
        profile: "Profile",
        login: "Login",
        loginInternal: "Internal Login",
        admin: "Administration",
        adminUsers: "Users",
        adminSessions: "Sessions",
        buy: "Buy",
        support: "Support",
    },
    new: {
        title: "New Acquisition Session",
        namelabel: "Session Name",
        namerequired: "Session name required",
        patternError: "Valid characters: <b>A-Z</b>, <b>a-z</b>, <b>0-9</b> and <b>_(),;.+-</b>",
        defaultName: "New Custom Session Name",
        customization: "Methodological report customization",
        subtitlelabel: "Subtitle",
        subtitlehint: "This subtitle will be placed in the title page above the report's date (max 100 characters)",
        logolabel: "Upload the logo",
        logohint: "This logo will be placed in the title page. If not set the Web Forensics logo will be used (max size 1MB)",
        colorlabel: "Main color",
        colorhint: "This color will be used for the title page's band and the report's headers. It cannot be too bright, otherwise the text will be unreadable.",
        logoMaxSize: "Image bigger than 1MB",
        colorError: "Color too bright",
        subtitleError: "Subtitle too long",
        startButton: "Create Session",
        attention: "Warning",
        minCompatibilities: "Minimum requirements",
        recommended: "Recommended browsers",
        yourBrowser: "Current browser",
        minBrowser: "Using this browser some features, like copy/paste, may not be available.",
        notCompatible: "Your browser does not satisfies the minimum requirements.",
        subtitle: "Please use one of the recommended browsers for an optimal experience.",
        continue: "Are sure you want to proceed with the session?",
        yes: "Yes",
        no: "No",
        all: "All",
        leave: "Are you sure do you want to leave?",
        close: "Close",
        connectionError: "Connection not ready yet or there was a connection error",
        retry: "Reconnection retry in %{seconds} seconds",
        retryButton: "Retry",
        reset: "Reset",
        errorMessage: "The system is taking longer than expected to load the acquisition environment. Please check whether you have an stable internet connection and retry.",
        waitingMessage: "This initialization process could take about 3 minutes, please stay on this page without refreshing it or pressing back on the browser.<br/> You can cancel in any moment.",
        tips: {
            1: "The acquisition environment is a preconfigured virtual machine that provides the user with an isolated and extremely secure system.",
            2: "The video and audio qualities depend on the connexion speed and might not be optima. A slight latency will not affect the forensic proof.",
            3: "The acquisition environment represents the part of the interface that will be recorded into a video and will be a part of the forensic evidence package.",
            4: "One or more screenshots of the most important moments of the session could be useful to be presented in trial, together with the video recording, the methodological report and the forensic evidence package.",
            5: "The Log consoles show in real time: useful technical information, visited URLs and actions done by the underlying system. To show these information is a fundamental aspect to ensure the redundancy property of the forensic proof, this is a way to avoid disputes.",
            6: "The ZIP file generated during the session must be presented in court as is, it must not be opened. It should be accompanied by the methodological report, that provides the necessary elements to evaluate the evidence.",
        },
        discover: "Discover Web Forensics",
    },
    stats: {
        title: "Overview",
        remaining: "Remaining credits",
        used: "Used credits",
        completed: "Completed Sessions",
        buy: "Add credits",
        remainingtime: "Remaining acquisition time",
        nomorepart1: "It looks like you haven't got any credits left,",
        nomorepart2: "add more credits",
        nomorepart3: "to start a New Session",
        more: "Add more credits",
        moreWF: "Add more credits",
    },
    details: {
        titleSession: "Details",
        titleFiles: "Files",
        name: "Name",
        created: "Created",
        elapsed: "Elapsed",
        state: "State",
        download: "Download Package",
        previewNoFile: "Select a file",
        deleted: "Deleted",
        messagePCAP: "The file with PCAP extension contains the network traffic generated during the session. You can view it using software like for example, <a href='https://www.wireshark.org/' target='_blank'>WireShark</a>",
    },
    fileBrowser: {
        name: "Name",
        type: "Type",
        size: "Size",
        created: "Created",
        partialDownload: "Download the selected categories of files",
        [FileCategory.SCREENSHOT]: "Screenshots",
        [FileCategory.DOWNLOAD]: "Downloads",
        [FileCategory.LOG]: "Logs",
        [FileCategory.VIDEO]: "Video",
        [FileCategory.PCAP]: "Pcap",
        [FileCategory.KEYS]: "Keys",
        [FileCategory.XML]: "Xml",
        [FileCategory.MHTML]: "Source and resources",
        [FileCategory.WACZ]: "WACZ archive",
    },
    login: {
        username: "Username",
        usernamerequired: "Username is required",
        password: "Password",
        passwordrequired: "Password is required",
        button: "Login",
        lostPassword: "Forgot password?",
        internal: "Internal login",
    },
    acquisitions: {
        titleComplete: "Sessions",
        titleLastFive: "Last Sessions",
        sessionDeleted: "Session deleted",
        table: {
            name: "Name",
            created: "Created",
            urls: "Url total",
            state: "State",
            actions: {
                pdf: "PDF",
                pdftip: "Download the methodological report",
                pcap: "ZIP",
                pcaptip: "Download the forensic evidence",
                pcapAlert: `
                    This forensic evidence package must be presented in court as is, it must not be opened. It should be accompanied by the methodological report, that provides the necessary elements to evaluate the evidence.
                    <br/><br/>
                    You can download the package or share the following signed link that will be valid for the next 7 days:
                    <br>
                    <div class="container archive-link">
                        <div class="row">
                            <div class="col-xs-2 col-2" style="width: auto; padding: 0;">
                                <div id="archive-copy-button" class="copy" style="margin-top: 20px; margin-right: 5px; cursor:pointer;"
                                onclick="
                                    var copyText = document.getElementById('archive-link');
                                    copyText.select();
                                    copyText.setSelectionRange(0, 99999);
                                    navigator.clipboard.writeText(copyText.value);
                                    var archiveCopyButton = document.getElementById('archive-copy-button');
                                    archiveCopyButton.setAttribute('class', 'copied');        
                                    var archiveCopyText = document.getElementById('archive-copy-text');
                                    archiveCopyText.innerHTML = 'COPIED';
                                    setTimeout(function(){ archiveCopyButton.setAttribute('class', 'copy')}, 3000);
                                    setTimeout(function(){ archiveCopyText.innerHTML = 'COPY'}, 3000);
                                ">
                                <i class="fal fa-copy" ></i> <span id="archive-copy-text" style="font-size:11px;">COPY</span></div>
                            </div>
                            <div class="col-xs-10 col-10 k-input-group">
                                <input readonly id="archive-link" style="width:100%; margin-top: 10px;" value="%{link}" class="k-input form-control"/>
                            </div>
                        </div>
                    </div>
                `,
                pcapAlertButtonYes: "Download",
                pcapAlertButtonNo: "Close",
                view: "Open",
                viewtip: "Details of the forensic acquisition",
                abort: "Abort",
                aborttip: "Abort this sessions without consuming any credits",
                retry: "Retry",
                retrytip: "A new session will be created with the same name",
                del: "Delete",
                deltip: "Deletes the session",
                enter: "Resume",
                entertip: "Resume the session",
            },
        },
        stateValues: {
            [SessionSimpleState.READY]: "Ready",
            [SessionSimpleState.INITIALIZING]: "Initializing",
            [SessionSimpleState.STARTED]: "Started",
            [SessionSimpleState.FAILED]: "Failed",
            [SessionSimpleState.COMPLETED]: "Completed",
            [SessionSimpleState.FINALIZING]: "Finalizing",
            [SessionSimpleState.ABORTED]: "Aborted",
        },
        warnTitle: "On going sessions",
        warnDescription: "The following sessions are <b>on going</b> and are potentially consuming credits. <br/>Proceed with the acquisitions by clicking the <b>RESUME</b> button. <br/>If the session have a duration of less than 5 minutes you can cancel it for <b>free</b>.",
    },
    date: {
        short: "MM/DD/YYYY",
        long: "MM/DD/YYYY hh:mm a",
    },
    kptable: {
        filters: "Filters",
        apply: "Apply",
        show_columns: "Show columns",
        pager: {
            first: "First",
            last: "Last",
        },
        actions: "Actions",
        date: {
            short: "MM/DD/YYYY",
            long: "MM/DD/YYYY hh:mm a",
        },
        confirm: {
            yes: "Yes",
            no: "No",
        },
        show: "Show",
        globalSelectionWarn: "This selection only applies for the %{count} visible elements",
    },
    admin: {
        users: {
            title: "Users",
            table: {
                email: "Email",
                name: "Name",
                lastname: "Lastname",
                provider: "Provider",
                consumedCredits: "Credits",
                crmid: "crmid",
                externalId: "EID",
                actionOpenSessions: "Open sessions",
            },
        },
        sessions: {
            title: "Sessions",
            missing: "There is no selected user, ",
            back: "go to selection",
            table: {
                name: "Name",
                created: "Created",
                state: "State",
                start: "Started",
                end: "Concluded",
                signingDate: "Signing Date",
                lastHeartBeat: "Last Heartbeat",
                id: "Id",
                consumedCredits: "Credits",
                elapsed: "Ellapsed",
                softDeleted: "Deleted",
                actions: {
                    refund: "Refund whole",
                    refundOne: "Refund one credit",
                    changeOwner: "Change Owner",
                    terminate: "Terminate",
                    stop: "Stop",
                    fail: "Fail",
                    deletePdf: "Reset PDF",
                    deletePdftip: "Deletes the pregenerated PDF, the next time the user downloads them, they will be regenerated",
                    refundtip: "Refund this section to the user",
                    refundOneTip: "Refund one credit of this session to the user",
                    changeOwnertip: "Change this session's owner",
                    terminatetip: "Terminate the VM associated with this session",
                    stoptip: "Stop the VM associated with this session",
                    failtip: "Force this session to fail",
                    del: "Delete",
                    deltip: "Deletes the session",
                },
            },
            msg: {
                refund: "Session refunded",
                refundOne: "Credit refunded",
                changeOwner: "Session's owner changed",
                terminate: "Session transitioned to the respective terminated state",
                stop: "Session transitioned to the respective stopped state",
                fail: "Session forced to fail",
                del: "Session deleted",
            },
            stateValues: {
                [SessionState.VM_READY]: "Ready",
                [SessionState.VM_ABORTED_TIMEOUT_TERMINATED]: "Aborted timeout and terminated",
                [SessionState.VM_ABORTED_ERROR_TERMINATED]: "Aborted error and terminated",
                [SessionState.VM_ABORTED_MANUAL_TERMINATED]: "Aborted manually and terminated",
                [SessionState.ARTIFACTS_UPLOADED]: "Acquisizion files uploaded",
                [SessionState.VM_ABORTED_MANUAL]: "Aborted manually",
                [SessionState.GURU_INTERVENTION_STOPPED]: "GURU intervention and stopped",
                [SessionState.FATAL_FAILED_TERMINATED]: "Fatal failed and terminated",
                [SessionState.VM_PREPARING]: "Preparing",
                [SessionState.VM_ABORTED_ERROR]: "Aborted Error",
                [SessionState.GURU_INTERVENTION]: "GURU Intervention",
                [SessionState.CLOSED]: "Closed",
                [SessionState.CLOSING]: "Closing",
                [SessionState.FATAL_FAILED]: "Fatal Failed",
                [SessionState.STARTED]: "Started",
                [SessionState.VM_ABORTED_TIMEOUT]: "Aborted timeout",
                [SessionState.VM_TERMINATED]: " Completed and terminated",
            },
            coModal: {
                title: "Change owner for session",
                closeButton: "Close",
                changeButton: "Change",
                selectRequired: "Please select a user",
            },
        },
    },
};
export default en;
