import {LandingNames} from "../1.components/landings";
import {LANDING} from "./constants";

export enum Feature {
    AUTH0= "auth0",
    INTERNAL= "internal",
    CHARGING= "charging",
    GFL= "gfl",
}

type FeaturesConf = {
    [key in Feature]: boolean;
}

const featureMap: {[key in LandingNames]: FeaturesConf} = {
    [LandingNames.KOPJRA_LANDING]: {
        auth0: true,
        internal: true,
        charging: true,
        gfl: false,
    },
    [LandingNames.GFL_LANDING]: {
        auth0: false,
        internal: true,
        charging: false,
        gfl: true,
    }
}

export function hasFeature(feature: Feature) {
    return featureMap[LANDING][feature] || false;
}