import {getParser, parse} from "bowser";

export function getBrowserVersion() {
    return parse(window.navigator.userAgent);
}

export const checkTreeMin = {
    desktop: {
        "Chrome": ">=61",
        "Microsoft Edge": ">=79",
//        "Internet Explorer": "",
        "Opera": ">=51",
        "Safari": ">=11",
        "Firefox": ">=68"
    }
};

export const checkTreeCopyPaste = {
    desktop: {
        "Chrome": ">=66",
        "Microsoft Edge": ">=79",
        "Opera": ">=56",
    }
};

export const checkTreeVideo = {
    desktop: {
        "Chrome": ">=61",
        "Microsoft Edge": ">=79",
    }
};

export const compatibilityTexts: {[key: string]: {name: string; version: string; url: string;}} = {
    Chrome: {name: "Chrome", version: "66", url: "https://www.google.com/chrome/"},
    "Microsoft Edge": {name: "Microsoft Edge", version: "79", url: "https://www.microsoft.com/edge"},
    Opera: {name: "Opera", version: "56", url: "https://www.opera.com/download"},
};

export function minimumRequirements(): boolean {
    return !!getParser(window.navigator.userAgent).satisfies(checkTreeMin);
}

export function copyPasteRequirements(): boolean {
    return !!getParser(window.navigator.userAgent).satisfies(checkTreeCopyPaste);
}

export function videoRequirements(): boolean {
    return !!getParser(window.navigator.userAgent).satisfies(checkTreeVideo);
}
