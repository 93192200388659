export class LongPoller {
    private readonly func: () => (void | Promise<void>);
    private readonly interval: number;
    private started: boolean;
    private idTimeout: NodeJS.Timeout | undefined;

    constructor(func: () => void | Promise<void>, interval: number) {
        this.func = func;
        this.interval = interval;
        this.started = false;
    }

    private longPoll() {
        if (this.started) {
            this.idTimeout = setTimeout(() => {
                this.func();
                this.longPoll();
            }, this.interval);
        }
    }

    public start(): void {
        this.started = true;
        this.longPoll();
    }

    public stop(): void {
        this.started = false;
        if (this.idTimeout) {
            clearTimeout(this.idTimeout);
        }
    }
}