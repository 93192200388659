import {createReducer} from "typesafe-actions";
import update from "immutability-helper";
import {SessionsState, SessionState} from "../5.types/sessionsstate";
import {
    acquire,
    cancelSession,
    changeSessionName,
    cleanSessions,
    closeModal,
    gotSessions,
    gotSessionStats,
    newSession,
    openModal,
    setPoller,
    setSession,
    setSessionDetail,
    setSessionError,
    toAborted,
    unsetSessionError
} from "../3.actions/sessions";
import {stopPollers} from "../6.utils/commons";

const initialState: SessionsState = {
    total: 0,
    openedModal: false,
};

export const sessionsReducer = createReducer(initialState)
    .handleAction(gotSessions, (state, action) => update(state, {
        list: {$set: action.payload.results},
        total: {$set: action.payload.total},
    }))
    .handleAction(cleanSessions, (state) => update(state, {
        list: {$set: undefined},
        total: {$set: 0},
    }))
    .handleAction(newSession, (state) => update(state, {
        newSession: {$set: {acquiring: false, pollers: {}}},
    }))
    .handleAction(cancelSession, (state, action) => {
        if (state.newSession) {
            stopPollers(state.newSession.pollers);
        }
        return update(state, {
            newSession: {$set: undefined},
            session: {$set: undefined},
        });
    })
    .handleAction(changeSessionName, (state, action) => update(state, {
        newSession: {
            name: {$set: action.payload}
        },
    }))
    .handleAction(gotSessionStats, (state, action) => update(state, {
        stats: {$set: action.payload},
    }))
    .handleAction(acquire, (state, action) => update(state, {
        newSession: {
            acquiring: {$set: true},
        },
    }))
    .handleAction(setSessionError, (state, action) => state.newSession ? update(state, {
        newSession: {
            error: {$set: action.payload},
            acquiring: {$set: false},
        },
    }) : state)
    .handleAction(unsetSessionError, (state, action) => state.newSession ? update(state, {
        newSession: {
            error: {$set: undefined},
            acquiring: {$set: true},
        },
    }) : state)
    .handleAction(setSession, (state, action) => update(state, {
        session: {$set: action.payload},
    }))
    .handleAction(setPoller, (state, action) => update(state, {
        newSession: {
            pollers: {[action.payload.name]: {$set: action.payload.poller}},
        },
    }))
    .handleAction(setSessionDetail, (state, action) => update(state, {
        detail: {$set: action.payload},
    }))
    .handleAction(toAborted, (state, action) => {
        if (state.list) {
            const sessionIndex = state.list.findIndex((s) => s.id.toString() === action.payload.toString());
            if (sessionIndex !== -1) {
                return update(state, {
                    list: {
                        [sessionIndex]: {state: {$set: SessionState.VM_ABORTED_MANUAL}},
                    },
                });
            } else {
                return state;
            }
        } else {
            return state;
        }
    })
    .handleAction(openModal, (state, action) => update(state, {
        openedModal: {$set: true},
    }))
    .handleAction(closeModal, (state, action) => update(state, {
        openedModal: {$set: false},
    }));
