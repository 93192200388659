/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import {CSSProperties, useEffect, useRef, useState} from "react";
import useWindowDimensions from "../6.utils/dimensionsHook";
import {SessionConnection} from "../5.types/sessionsstate";
// @ts-ignore
import Guacamole from "guacamole-common-js";
import {I18n, Translate} from "react-redux-i18n";
import {LongPoller} from "../6.utils/LongPoller";
import {buildStyles, CircularProgressbarWithChildren} from "react-circular-progressbar";
import logoBig from "../8.resources/imgs/wf_icon_large.svg";
import {isControlOrCommand, mapCommandToControl} from "../6.utils/commons";
import {confirmBox, KButton, KBUTTON_SIZE, KBUTTON_VARIANT, KSpace} from "@kopjra/uikit";
import {Col, Row} from "react-bootstrap";

export interface StateProps {
}

export interface DispatchProps {
}

export interface InnerProps {
    show: boolean;
    sessionId: string | number | undefined;
    connection?: SessionConnection;
    onDisconnect: () => void | Promise<void>;
    onUnMount: () => void | Promise<void>;
    onAbort: (sessionId?: string | number) => void | Promise<void>;
}

export enum TunnelState {
    CONNECTING,
    OPEN,
    CLOSED,
    UNSTABLE,
}

export type Props = StateProps & DispatchProps & InnerProps;

const keyboard = new Guacamole.Keyboard(document);

const TIMEOUT: number = 360;

let browserBack = false;

const tabEventHandler = (event: KeyboardEvent) => {
    // if (event.key === "Tab") {
    event.preventDefault();
    // }
}

export const GuacamoleDisplay: React.FC<Props> = ({connection, show, onDisconnect, onUnMount, onAbort, sessionId}) => {
    const {width, height} = useWindowDimensions();
    const displayDiv = useRef<HTMLDivElement>(null);
    const [client, setClient] = useState<any>(undefined);
    // This is used to have the updated client value in the unmount effect
    const clientRef = useRef<any>(undefined);
    const [mouse, setMouse] = useState<any>(undefined);
    const [innerTimer, setInnerTimer] = useState<number>(0);
    const [poller, setPoller] = useState<LongPoller | undefined>(undefined);
    const [connected, setConnected] = useState<boolean>(false);

    useEffect(() => {
        if (!connected) {
            let counter = 0;
            const p = new LongPoller(() => {
                if (counter > TIMEOUT) {
                    onDisconnect();
                    p.stop();
                } else {
                    if ((counter + 1) % 4 === 0) {
                        setClient(undefined);
                    }
                    setInnerTimer(counter++);
                }
            }, 1000);
            p.start();
            setPoller(p);
        } else {
            setInnerTimer(0);
            if (poller) {
                poller.stop();
            }
            setPoller(undefined);
        }
    }, [connected]);

    useEffect(() => {
        return () => {
            if (!browserBack) {
                keyboard.onkeydown = null;

                keyboard.onkeyup = null;

                document.removeEventListener('keydown', tabEventHandler, false);

                if (clientRef.current) {
                    clientRef.current.disconnect();
                    onUnMount();
                }
            } else {
                browserBack = true;
            }
        }
    }, [onUnMount]);

    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);
        const backListener = async (evt: PopStateEvent) => {
            browserBack = true;
            if (await confirmBox({
                dark: true,
                message: I18n.t("new.leave"),
                yesText: I18n.t("new.yes"),
                noText: I18n.t("new.no"),
            })) {
                browserBack = false;
                window.history.back();
            } else {
                window.history.pushState(null, document.title, window.location.href);
            }
        };
        const unloadListener = (ev: BeforeUnloadEvent) => {
            ev.preventDefault();
            return ev.returnValue = I18n.t("new.leave");
        };
        window.addEventListener("popstate", backListener);
        window.addEventListener("beforeunload", unloadListener);
        return () => {
            window.removeEventListener("popstate", backListener);
            window.removeEventListener("beforeunload", unloadListener);
        }
    }, []);

    useEffect(() => {
        if (connection) {
            const vmWidth = 1920;
            const vmRatio = 1.77777777777778;

            let guideWidth = width;
            let guideHeight = height;
            if (width / height > vmRatio) {
                guideWidth = height * vmRatio;
            } else {
                guideHeight = width / vmRatio;
            }
            const scaleFactor = (guideWidth / vmWidth);

            const mouseMoveHandler = (gClient: any) => (mouseState: any) => {
                mouseState.fromClientPosition(displayDiv.current, mouseState.x, mouseState.y);
                if (mouseState.x > guideWidth || mouseState.y > guideHeight) {
                    return;
                }
                mouseState.x = (mouseState.x) / scaleFactor;
                mouseState.y = (mouseState.y) / scaleFactor;
                gClient.sendMouseState(mouseState);
            };

            // Compute the scale factor with respect to the original VM resolution
            let connectionString = `token=${connection.token}`;
            // Add audio params to the connection string
            Guacamole.AudioPlayer.getSupportedTypes().forEach(function (mimetype: string) {
                connectionString += "&GUAC_AUDIO=" + encodeURIComponent(mimetype);
            });

            let guacClient = client;
            if (!guacClient) {
                // Create a websocket tunnel
                const tunnel = new Guacamole.WebSocketTunnel(connection.tunnelUrl);

                // Events for when the connection closes
                (tunnel as any).onerror = (error: Error) => {
                    if (error && connected) {
                        onDisconnect();
                        setConnected(false);
                    }
                };
                (tunnel as any).onstatechange = (state: number) => {
                    switch (state) {
                        case TunnelState.CLOSED:
                            if (connected) {
                                onDisconnect();
                                setConnected(false);
                            }
                            break;
                        case TunnelState.OPEN:
                            setConnected(true);
                            break;
                    }
                };

                // Instantiate client, using an HTTP tunnel for communications and connects.
                guacClient = new Guacamole.Client(tunnel);
                guacClient.connect(connectionString);

                guacClient.getDisplay().scale(scaleFactor);

                const element = guacClient.getDisplay().getElement();
                if (displayDiv.current) {
                    displayDiv.current.innerHTML = "";
                    displayDiv.current.appendChild(element);
                }

                // Mouse setting
                const guacMouse = new Guacamole.Mouse(element);

                guacMouse.onmousedown =
                    guacMouse.onmouseup = (mouseState: any) => {
                        guacClient.sendMouseState(mouseState);
                    };

                guacMouse.onmousemove = mouseMoveHandler(guacClient);

                document.addEventListener('keydown', tabEventHandler, false);

                let controlOrCommandPressed = false;
                keyboard.onkeydown = async (keysym: any) => {
                    if (controlOrCommandPressed) {
                        const clipboard = window.navigator.clipboard;
                        if (keysym === 99 || keysym === 120) {
                            if (clipboard) {
                                clipboard.writeText("").catch(console.log);
                            }
                        } else if (keysym === 118) {
                            if (clipboard) {
                                let clipText: string | undefined;
                                try {
                                    clipText = await clipboard.readText();
                                    console.log(clipText);
                                } catch (error) {
                                    clipText = "";
                                }
                                if (clipText) {
                                    const clipStream = guacClient.createClipboardStream("plain/text");
                                    const writer = new Guacamole.StringWriter(clipStream);
                                    writer.sendText(clipText);
                                    writer.sendEnd();
                                }
                            }
                        }
                    }

                    if (isControlOrCommand(keysym)) {
                        controlOrCommandPressed = true;
                        console.log("CONTROL OR COMMAND PRESSED");
                    }

                    guacClient.sendKeyEvent(1, mapCommandToControl(keysym));
                    console.log(`KEY PRESSED ${mapCommandToControl(keysym)}`);
                }

                keyboard.onkeyup = async (keysym: any) => {
                    if (isControlOrCommand(keysym)) {
                        controlOrCommandPressed = false;
                        console.log("CONTROL OR COMMAND RELEASED");
                    }

                    guacClient.sendKeyEvent(0, mapCommandToControl(keysym));
                    console.log(`KEY RELEASED ${mapCommandToControl(keysym)}`);

                }

                guacClient.onaudio = (stream: any, mimetype: any) => {
                    console.log(`stream: ${JSON.stringify(stream)}`);
                    console.log(`mimetype: ${JSON.stringify(mimetype)}`);
                    return Guacamole.AudioPlayer.getInstance(stream, mimetype);
                };

                clientRef.current = guacClient;
                setClient(guacClient);
                setMouse(guacMouse);
            } else {
                guacClient.getDisplay().scale(scaleFactor);
                mouse.onmousemove = mouseMoveHandler(guacClient);
            }
        }
    }, [connection, width, height, client, onDisconnect, mouse]);

    let containerStyle: CSSProperties = {
        opacity: (show) ? 1 : 0,
        top: 0,
        left: 0,
        width,
        backgroundColor: "#363D49",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflowY: "auto",
    };
    if (connected) {
        containerStyle.height = height;
        containerStyle.position = "fixed";
    } else {
        containerStyle.minHeight = "100vh";
        containerStyle.position = "absolute";
    }
    return (
        <div style={containerStyle} className="guacDisplay">
            {(connection) ? (
                <div ref={displayDiv} style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    cursor: "none",
                    display: connected ? "inherit" : "none",
                }}/>
            ) : null}
            {(!connected) ? (
                <>
                    <KButton
                        text={<><i className="fal fa-lg fa-times"/>&nbsp;&nbsp;<Translate value="bar.cancelbutton"/></>}
                        variant={KBUTTON_VARIANT.secondary}
                        onClick={() => onAbort(sessionId)}
                        size={KBUTTON_SIZE.xs}
                        style={{position: "fixed", top: 16, right: 17}}
                    />

                    <div className="text-center d-xxl-none">
                        <KSpace spaces={2}/>
                        <Row>
                            <Col className="text-center">
                                <div style={{width: 126, height: 126, display: "inline-block"}}>
                                    <CircularProgressbarWithChildren maxValue={TIMEOUT} value={innerTimer} strokeWidth={3}
                                                                     styles={buildStyles({
                                                                         pathColor: "#5198F4",
                                                                         trailColor: "rgba(54, 61, 73, 0)",
                                                                     })}>
                                        <img
                                            alt="logo_big"
                                            src={logoBig}
                                            height={77}
                                        />
                                    </CircularProgressbarWithChildren>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={9} lg={10} md={11} sm={12} className="text-center">
                                <span style={{color: "#5198F4", fontSize: "13px", width: "100%"}}><Translate
                                    value="new.waitingMessage" dangerousHTML={true}/></span>
                            </Col>
                        </Row>
                        <KSpace spaces={2}/>
                        <Row>
                            <Col className="text-center">
                                <span style={{color: "#4e596b", fontSize: "18px", width: "100%", textTransform: "uppercase"}}><Translate
                                    value="new.discover" dangerousHTML={true}/></span>
                            </Col>
                        </Row>
                        <KSpace spaces={0.5}/>
                        <Row>
                            <Col xl={9} lg={10} md={11} sm={12}>
                                <iframe width="100%" height="400" src="https://www.youtube.com/embed/omk68q56CgM?si=6QHQOBupLe3asceK?color=white&amp;modestbranding=1&amp;rel=0&amp;controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                            </Col>
                        </Row>
                        {/*<TipCarousel style={{marginTop: 150}}/>*/}
                    </div>
                    <div className="text-center d-none d-xxl-block">
                        <KSpace spaces={2}/>
                        <Row>
                            <Col className="text-center">
                                <div style={{width: 126, height: 126, display: "inline-block"}}>
                                    <CircularProgressbarWithChildren maxValue={TIMEOUT} value={innerTimer} strokeWidth={3}
                                                                     styles={buildStyles({
                                                                         pathColor: "#5198F4",
                                                                         trailColor: "rgba(54, 61, 73, 0)",
                                                                     })}>
                                        <img
                                            alt="logo_big"
                                            src={logoBig}
                                            height={77}
                                        />
                                    </CircularProgressbarWithChildren>
                                </div>
                            </Col>
                        </Row>
                        <KSpace spaces={3}/>
                        <Row>
                            <Col lg={10} md={11} sm={12} className="text-center">
                                <span style={{color: "#5198F4", fontSize: "13px", width: "100%"}}><Translate
                                    value="new.waitingMessage" dangerousHTML={true}/></span>
                            </Col>
                        </Row>
                        <KSpace spaces={4}/>
                        <Row>
                            <Col className="text-center">
                                <span style={{color: "#4e596b", fontSize: "18px", width: "100%", textTransform: "uppercase"}}><Translate
                                    value="new.discover" dangerousHTML={true}/></span>
                            </Col>
                        </Row>
                        <KSpace spaces={0.5}/>
                        <Row>
                            <Col lg={10} md={11} sm={12}>
                                <iframe width="100%" height="400" src="https://www.youtube.com/embed/omk68q56CgM?si=6QHQOBupLe3asceK?color=white&amp;modestbranding=1&amp;rel=0&amp;controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                            </Col>
                        </Row>
                        {/*<TipCarousel style={{marginTop: 150}}/>*/}
                    </div>
                </>
            ) : null}
        </div>
    );
};
