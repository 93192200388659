import * as React from "react";
import {useEffect, useState} from "react";
import {Button, Carousel, Col, ProgressBar, Row} from "react-bootstrap";
import {Translate} from "react-redux-i18n";
import {SessionStats} from "../5.types/sessionsstate";
import {SESSION_DURATION_MINUTES} from "../6.utils/constants";
import moment from "moment";
import {formatNumber} from "../6.utils/commons";
import {LongPoller} from "../6.utils/LongPoller";
import {StatsLoader} from "./StatsLoader";
import {Feature, hasFeature} from "../6.utils/features";
import {HelpModalIcon, IMAGES, KButton, KBUTTON_SIZE, KBUTTON_VARIANT, KCard, KSpace} from "@kopjra/uikit";
import tip1 from "../8.resources/imgs/WebForensics_WelcomeScreen_Screenshots_Tip1.jpg";
import tip3 from "../8.resources/imgs/WebForensics_WelcomeScreen_Screenshots_Tip3.jpg";
import {useAuth0} from "@auth0/auth0-react";

export interface StateProps {
    stats?: SessionStats;
    storeUrl?: string;
    fullName: string;
}

export interface DispatchProps {
    onGetSessionStats: () => Promise<void>;
    onNewSession: () => void;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

let statsPoller: LongPoller | undefined;

export const Stats: React.FC<Props> = ({fullName, stats, onGetSessionStats, onNewSession, storeUrl}) => {
    const {user} = useAuth0();
    useEffect(() => {
        onGetSessionStats().catch((e) => console.warn("Get Session Stats error", e));
        statsPoller = new LongPoller(() => onGetSessionStats().catch((e) => console.warn("Get Session Stats error", e)), 10000);
        statsPoller.start();
        return () => statsPoller && statsPoller.stop();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const [activeIndex, setActiveIndex] = useState(0);
    const [show, setShow] = useState<boolean | undefined>();
    let remainingTimeStr = "";
    if (stats && stats.remaining > 0) {
        const minutes = stats.remaining * SESSION_DURATION_MINUTES;
        const duration = moment.duration(minutes, "minutes");
        remainingTimeStr = `-${formatNumber(Math.floor(duration.asHours()))}:${formatNumber(duration.minutes())}:${formatNumber(duration.seconds())}`;
    }

    return (
        <KCard header={<Translate value="stats.title"/>} helpModal={{
            show,
            onShow: (s) => setShow(s),
            content: (
                <Carousel interval={null} fade={true} className="welcome text-center" activeIndex={activeIndex} onSelect={(selected) => setActiveIndex(selected)}>
                    <Carousel.Item>
                        <div style={{display: "block"}}>
                            <KSpace spaces={5}/>
                            <img width={125} src={IMAGES["web-forensics"].still.large.dark} alt={"wf"}/>
                            <KSpace spaces={2}/>
                            <h1><Translate value="home.welcome.title"/>{fullName ? `, ${fullName}` : ""}</h1>
                            <h3><Translate value={"home.welcome.subtitle"}/></h3>
                            <KSpace spaces={7}/>
                            <Row>
                                <Col md={4}><KButton text={<Translate value={"home.welcome.skip"}/>} fill={true} variant={KBUTTON_VARIANT.secondary} onClick={() => setShow(false)}/></Col>
                                <Col md={4}><KButton text={<Translate value={"home.welcome.next"}/>} fill={true} variant={KBUTTON_VARIANT.primary} onClick={() => setActiveIndex(activeIndex + 1)}/></Col>
                            </Row>
                            <KSpace spaces={2}/>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img width={560} className={"tip"} src={tip1} alt={"dash1"}/>
                        <KSpace spaces={2}/>
                        <p><Translate value={"home.welcome.tip1"}/></p>
                        <KSpace spaces={2}/>
                        <Row>
                            <Col md={4}><KButton text={<Translate value={"home.welcome.skip"}/>} fill={true} variant={KBUTTON_VARIANT.secondary} onClick={() => setShow(false)}/></Col>
                            <Col md={4}><KButton text={<Translate value={"home.welcome.next"}/>} fill={true} variant={KBUTTON_VARIANT.primary} onClick={() => setActiveIndex(activeIndex + 1)}/></Col>
                        </Row>
                        <KSpace spaces={2}/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="iframe-container">
                            <iframe width="560" height="310" src="https://www.youtube.com/embed/omk68q56CgM?si=6QHQOBupLe3asceK?color=white&amp;modestbranding=1&amp;rel=0&amp;controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                        </div>
                        <KSpace spaces={2}/>
                        <p><Translate value={"home.welcome.tip2"}/></p>
                        <KSpace spaces={2}/>
                        <Row>
                            <Col md={4}><KButton text={<Translate value={"home.welcome.skip"}/>} fill={true} variant={KBUTTON_VARIANT.secondary} onClick={() => setShow(false)}/></Col>
                            <Col md={4}><KButton text={<Translate value={"home.welcome.next"}/>} fill={true} variant={KBUTTON_VARIANT.primary} onClick={() => setActiveIndex(activeIndex + 1)}/></Col>
                        </Row>
                        <KSpace spaces={2}/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img width={560} className={"tip"} src={tip3} alt={"dash1"}/>
                        <KSpace spaces={2}/>
                        <p><Translate value={"home.welcome.tip3"}/></p>
                        <KSpace spaces={2}/>
                        <Row>
                            <Col md={4}><KButton text={<Translate value={"home.welcome.gotodash"}/>} fill={true} variant={KBUTTON_VARIANT.primary} onClick={() => setShow(false)}/></Col>
                        </Row>
                        <KSpace spaces={2}/>
                    </Carousel.Item>
                </Carousel>
            ),
            tooltip: <Translate value="home.welcome.tooltip"/>,
            icon: HelpModalIcon.INFO,
        }}>
            {(stats) ? (
                <>
                    <KSpace spaces={2}/>
                    {(stats.remaining === 0 && stats.used === 0 && stats.completed === 0 && !stats.infinite) ? (
                        <Row>
                            <Col className="landing text-center">
                                <img src={IMAGES["web-forensics"].still.large.dark} height={120} alt={"Web Forensics"}/>
                                <span className={"appName"}>Web Forensics</span>
                                {/*<h4 style={{paddingTop: 20}}><Translate value="home.payoff"/></h4>*/}
                                <h4 className="call" style={{paddingTop: 50}}><Translate value="home.call"/></h4>
                                <div style={{paddingTop: 50}}>
                                    <Button
                                        variant={"primary"}
                                        onClick={() => {
                                            if (user?.partner === "opendotcom") {
                                                window.open("https://www.opendotcom.it/digital-forensics/acquista.aspx", "_blank");
                                            } else {
                                                window.open("https://store.kopjra.com", "_blank");
                                            }
                                        }}
                                    >
                                        <i className="fal fa-shopping-cart"/> <Translate value="bar.buy"/>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    ) : (
                        <>
                            <Row>
                                {(!stats.infinite) ? (
                                    <>
                                        <Col className="text-center" md={{span: 3}} xs={4}>
                                            {(stats.remaining > 0) ? (
                                                <span className="bigNumber text-primary">{stats.remaining}</span>
                                            ) : (
                                                <span className="bigNumber text-danger">{stats.remaining}</span>
                                            )}
                                        </Col>
                                        <Col className="text-center" md={{span: 3}} xs={4}>
                                            <span className="bigNumber text-secondary">{stats.used}</span>
                                        </Col>
                                    </>
                                ) : (
                                    <Col className="text-center" md={{span: 3}} xs={8}>
                                        <span className="bigNumber text-primary"><i className="fa fa-infinity"/></span>
                                    </Col>
                                )}
                                <Col className="text-center" md={{span: 3}} xs={4}>
                                    <span className="bigNumber text-success">{stats.completed}</span>
                                </Col>
                            </Row>
                            <Row style={{marginTop: -10}}>
                                {(!stats.infinite) ? (
                                    <>
                                        <Col className="text-center" md={{span: 3}} xs={4}>
                                            <span className="sessionLabel"><Translate value="stats.remaining"/></span>
                                        </Col>
                                        <Col className="text-center" md={{span: 3}} xs={4}>
                                            <span className="sessionLabel"><Translate value="stats.used"/></span>
                                        </Col>
                                    </>
                                ) : (
                                    <Col className="text-center" md={{span: 3}} xs={8}>
                                        <span className="sessionLabel"><Translate value="stats.remaining"/></span>
                                    </Col>
                                )}
                                <Col className="text-center" md={{span: 3}} xs={4}>
                                    <span className="sessionLabel"><Translate value="stats.completed"/></span>
                                </Col>
                            </Row>
                            {(stats.remaining > 0 || stats.infinite) ? (
                                <>
                                    {(!stats.infinite) ? (
                                        <Row style={{marginTop: 50}}>
                                            <Col className="text-end" md={{span: 3}} xs={4}>
                                                <span className="sessionLabel"><Translate value="stats.remainingtime"/></span>
                                            </Col>
                                            <Col className="text-center" md={{span: 4}} xs={4} style={{paddingTop: 12}}>
                                                <span className="sessionLabel"><ProgressBar now={(stats!.used / (stats!.remaining + stats!.used))*100}/></span>
                                            </Col>
                                            <Col className="text-start" md={{span: 3}} xs={4}>
                                                <span className="sessionLabel">{remainingTimeStr}</span>
                                            </Col>
                                        </Row>
                                    ) : null }
                                    <Row style={{marginTop: 60}}>
                                        <Col md={{span: 4}} xs={12}>
                                            <KButton text={<><i className="fal fa-lg fa-plus"/>&nbsp;&nbsp;<Translate value="bar.createbutton"/></>}
                                                     variant={KBUTTON_VARIANT.success}
                                                     onClick={() => onNewSession()}
                                                     size={KBUTTON_SIZE.lg}
                                                     fill={true}
                                            />
                                        </Col>
                                    </Row>
                                    {(!stats.infinite) ? (
                                        <Row style={{marginTop: 20, marginBottom: 40}}>
                                            <Col className="text-center">
                                                {/*<span className="normalAnchor" style={{cursor: "pointer"}} onClick={() => onGetStoreUrl()}><Translate value="stats.more"/></span>*/}
                                                {hasFeature(Feature.GFL) ? (
                                                    <a className="normalAnchor" style={{cursor: "pointer"}} href={storeUrl || ""} target="_blank" rel="noopener noreferrer"><Translate value="stats.more"/></a>
                                                ) : (
                                                    <a className="normalAnchor" style={{cursor: "pointer"}} href={user?.partner === "opendotcom" ? "https://www.opendotcom.it/digital-forensics/acquista.aspx" : "https://store.kopjra.com"} target="_blank" rel="noopener noreferrer"><Translate value="stats.moreWF"/></a>
                                                )}

                                            </Col>
                                        </Row>
                                    ) : (
                                        <Row style={{marginTop: 20, marginBottom: 40}}/>
                                    )}
                                </>
                            ) : (
                                <>
                                    <Row style={{marginTop: 50}}>
                                        <Col className="text-center">
                                            <span className="normalText"><Translate value="stats.nomorepart1"/></span>&nbsp;
                                            {/*<span className="normalAnchor" style={{cursor: "pointer"}} onClick={() => onGetStoreUrl()}><Translate value="stats.nomorepart2"/></span>&nbsp;*/}
                                            <a className="normalAnchor" style={{cursor: "pointer"}} href={user?.partner === "opendotcom" ? "https://www.opendotcom.it/digital-forensics/acquista.aspx" : storeUrl || "https://store.kopjra.com"} target="_blank" rel="noopener noreferrer" ><Translate value="stats.nomorepart2"/></a>&nbsp;
                                            <span className="normalText"><Translate value="stats.nomorepart3"/></span>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: 40}}>
                                        <Col md={{span: 4}} xs={12}>
                                            <KButton text={<><i className="fal fa-lg fa-shopping-cart"/>&nbsp;&nbsp;<Translate value="stats.buy"/></>}
                                                     variant={KBUTTON_VARIANT.primary}
                                                     size={KBUTTON_SIZE.lg}
                                                     fill={true}
                                                     onClick={() => {
                                                         if (user?.partner === "opendotcom") {
                                                             window.open("https://www.opendotcom.it/digital-forensics/acquista.aspx", "_blank");
                                                         } else {
                                                             window.open(storeUrl || "https://store.kopjra.com", "_blank");
                                                         }
                                                     }}
                                            />
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </>
                    )}
                </>
            ): (
                <Row style={{marginTop: 50, marginBottom: 50}}>
                    <Col className="text-center">
                        <StatsLoader/>
                    </Col>
                </Row>
            )}
        </KCard>
    );
};
