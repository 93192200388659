import React from "react";
import {KCard, KContainer, KUserProfile, noop, UserProfile as UserProfileType} from "@kopjra/uikit";
import {Translate} from "react-redux-i18n";
import {Col, Row} from "react-bootstrap";
import {useAuth0} from "@auth0/auth0-react";
import {UserInfo, UserProfileData} from "../5.types/userstate";

export interface StateProps {
    extUser?: UserInfo;
}

export interface DispatchProps {
    onUpdateUserProfile: (up: UserProfileData) => Promise<void>;
    onResendEmail: () => Promise<void>;
    onResetPassword: () => Promise<void>;
}

export interface InnerProps {
}

export type Props = StateProps & DispatchProps & InnerProps;

export const UserProfile: React.FC<Props> = ({extUser, onResendEmail, onResetPassword, onUpdateUserProfile}: Props) => {
    const {user, isAuthenticated} = useAuth0();

    if (extUser && !user) {
        return <KContainer>
            <Row>
                <Col md={6}>
                    <KCard header={<Translate value="bar.profile"/>}>
                        <KUserProfile
                            user={{
                                picture: extUser.picture || "https://kopjra-public.s3.eu-west-1.amazonaws.com/user-circle-solid.svg",
                                email_verified: true,
                                email: extUser.email,
                                name: `${extUser.firstname} ${extUser.lastname}`,
                                sub: "",
                            }}
                            onResetPassword={async () => noop()}
                            onResendEmail={async () => noop()}
                            onUpdateUserProfile={async () => noop()}
                        />
                    </KCard>
                </Col>
            </Row>
        </KContainer>
    } else {
        return isAuthenticated ? (
            <KContainer>
                <Row>
                    <Col md={6}>
                        <KCard header={<Translate value="bar.profile"/>}>
                            <KUserProfile user={user as UserProfileType} onResetPassword={onResetPassword} onResendEmail={onResendEmail} onUpdateUserProfile={onUpdateUserProfile}/>
                        </KCard>
                    </Col>
                </Row>
            </KContainer>
        ) : null;
    }
}
