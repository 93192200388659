import * as React from "react";
import {History} from "history";
import { BrowserRouter, Route, useLocation } from "react-router-dom";
import {createContext, FC, useContext} from "react";
import {JustChildren} from "../5.types";
import { RouteChildrenProps } from "react-router";
import { Location } from "history";

const RouterContext = createContext<RouteChildrenProps>({} as RouteChildrenProps);

export let routerTools: History;

export const HookedBrowserRouter: FC<JustChildren> = ({ children }: JustChildren) => (
    <BrowserRouter>
        <Route>
            {(routeProps: RouteChildrenProps) => {
                routerTools = routeProps.history;
                return <RouterContext.Provider value={routeProps}>
                    {children}
                </RouterContext.Provider>;
            }}
        </Route>
    </BrowserRouter>
);

export function useRouter() {
    return useContext(RouterContext);
}

export function useQuery(): URLSearchParams {
    return new URLSearchParams(useLocation().search);
}

export enum Routes {
    ALL = "*",
    HOME = "/",
    LOGIN = "/login",
    USER_ACCOUNT = "/user/account",
    SESSIONS = "/sessions",
    A_SESSION = "/sessions/:sessionId",
    NEW_SESSION = "/new_session",
    ADMIN_USERS = "/admin/users",
    ADMIN = "/admin",
    MY_SESSIONS = "/my_sessions",
    ADMIN_SESSIONS = "/admin/sessions",
}

export function hasSessionId(location: Location): boolean {
    return !!location.pathname.match(/\/sessions\/.+/);
}
